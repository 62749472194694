import React, { useState } from 'react'
import BreadcrumbHeader from '../../layout/BreadcrumbsHeader'
import { useTheme } from '@emotion/react'
import ThemeBGWrapper from '../../ui-components/ThemeBGWrapper'
import { B300 } from '../../ui-components/CustomButton'
import { Add } from '@mui/icons-material'
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, LinearProgress, Menu, MenuItem, Tooltip, Typography, useMediaQuery } from '@mui/material'
import fundingRoundsBackground from "../../assets/rounds_icons/funding_rounds_background.webp"
import { FetchAllRounds } from '../../api/round'
import { ReactComponent as SecurityIcon } from "../../assets/rounds_icons/security_icon.svg"
import { ReactComponent as ViewIcon } from "../../assets/rounds_icons/view_icon.svg"
import { ReactComponent as MoreIcon } from "../../assets/rounds_icons/more_icon.svg"
import { useNavigate } from 'react-router-dom'
import rocket_rounds_image from "../../assets/rounds_icons/rocket_rounds_image.png"
import PerfectScrollbar from "react-perfect-scrollbar"
import moment from 'moment'
import { containerRef, handleDateValues } from '../../utils'
import { FetchAllAvatars, FetchProfileDetails } from '../../api'
import { TabContext, TabPanel } from '@mui/lab'
import CustomTabs from '../../ui-components/CustomTabs'
import { makeStyles } from '@mui/styles'
import PersonalDetails from './PersonalDetails'

const useStyles = makeStyles((theme) => ({
  scrollTabs: {
    '& > .Mui-disabled.MuiTabs-scrollButtons': {
      display: 'none',
      transition: 'all 1s ease'
    }
  }
}));

const Profile = () => {
  const theme = useTheme()
  const isNotLargeScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const navigate = useNavigate()

  const { data: userData, isLoading: isUserDataLoading } = FetchProfileDetails();
  const { data: avatarList, isRefetching: isRefetchingAvatarList, isLoading: isLoadingAvatarList, refetch: refetchAvatarList } = FetchAllAvatars()


  // console.log(roundsData, "roundsData")

  const RoundActions = () => {
    return (
      <B300 onClick={() => navigate("/rounds/create_round")} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 600, minWidth: isSmallScreen ? "0" : '64px', '& > .MuiButton-icon.MuiButton-startIcon': { marginRight: isSmallScreen ? 0 : '8px', marginLeft: isSmallScreen ? 0 : '-4px' } }} startIcon={<Add fontSize='1.25rem' />}>
        {!isSmallScreen ? "Add Round" : ''}
      </B300>
    )
  }

  let tabValue = new URLSearchParams(window.location.search).get("page");
  // console.log("tabl",tabValue)

  const [value, setValue] = useState(tabValue || "personal_details");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const roundTabs = [
    {
      label: "Personal Details",
      // icon: RoundDetailsIcon,
      value: "personal_details",
    },
    // {
    //   label: "Sectors",
    //   // icon: RoundInvestorsIcon,
    //   value: "sectors",
    // },
    // {
    //   label: "Stages",
    //   // icon: RoundDocumentsIcon,
    //   value: "stages"
    // }
  ]

  return (
    <ThemeBGWrapper bgColor={theme.palette.customColors.indigo25}>
      <BreadcrumbHeader title="Profile" breadcrumbTitle='Profile' customBackgroundColor={theme.palette.customColors.indigo25}
      // Actions={RoundActions} 
      />
      {
        isUserDataLoading ?
          <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingTop: 0 }}>
            {/* <CircularProgress sx={{ color: theme?.palette.customColors.b300 }} /> */}
            <Box className='skeleton' height={80}>

            </Box>
            <Box className='skeleton' height={"calc(100% - 80px)"} marginTop={2}>

            </Box>
          </Grid>
          :
          <>
            {userData && userData?.data &&
              <Grid xs={12} container bgcolor={"#F9F5FF"}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={value}>
                    <Box px={isSmallScreen ? "1rem" : "2rem"} bgcolor={"#fff"}>
                      <CustomTabs data={roundTabs} value={value} isSmallScreen={isSmallScreen} className={classes} handleChange={handleChange} key={"Tabs Tabs"} />
                    </Box>
                    <TabPanel value="personal_details" sx={{ paddingInline: 0 }}>
                      <PerfectScrollbar containerRef={containerRef} style={{ height: "calc(100vh - 210px)", paddingInline: isSmallScreen ? "1rem" : "2rem" }}>
                        <PersonalDetails data={userData?.data} avatarList = {avatarList} />
                      </PerfectScrollbar>
                    </TabPanel>
                    {/* <TabPanel value="sectors" sx={{ paddingInline: 0, paddingTop: '0.5rem' }}>
                      <PerfectScrollbar style={{ height: '100%', maxHeight: "calc(100vh - 180px)", paddingBottom: '0.5rem' }}>
                        <RoundInvestors currency={roundDetails?.currency || ''} />
                      </PerfectScrollbar>
                    </TabPanel>
                    <TabPanel value="stages" sx={{ paddingInline: isSmallScreen ? "1rem" : "2rem" }}>
                      <PerfectScrollbar style={{ height: "calc(100vh - 250px)" }}>
                        <RoundDocuments data={roundDetails?.documents} />
                      </PerfectScrollbar>
                    </TabPanel> */}
                  </TabContext>
                </Box>
              </Grid>}
          </>}
    </ThemeBGWrapper>
  )
}

export default Profile