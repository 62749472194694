import { Grid } from '@mui/material'
import React from 'react'
import background_login from "../assets/login_screen_image.webp"

const CustomBackgroundComponent = ({ bgImage, xs = 12, sm = 12, md = 5.4, lg = 5.4, xl = 5.4 }) => {
    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} sx={{display: {xs : "none", md: "flex"}, borderRadius: '16px', overflow:"hidden",maxHeight: "94vh", height:'100%'}}>
        {/* // , backgroundImage: `url(${background_login})` , backgroundSize: "fit", backgroundPosition: "center", backgroundRepeat: 'no-repeat',maxHeight: "94vh"}}> */}
            <img src={background_login} alt='login_image' loading='lazy' style={{ objectPosition: 'center', objectFit: "fill", width: "100%", height: "100%" }} />
        </Grid>
    )
}

export default CustomBackgroundComponent