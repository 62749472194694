import React from 'react';
import { Box, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useField } from 'formik';
import moment from 'moment';
import theme from '../../themes/theme';
import CustomInputField from './CustomInputField';
import CustomValidationText from '../CustomValidationText';

const CustomDatePicker = ({
    name,
    label,
    placeholder,
    minDate,
    maxDate,
    fullWidth = true,
    sx,
    labelStyle = {},
    InputProps = {},
    ...props
}) => {
    const [field, meta, helpers] = useField(name);

    const handleDateChange = (newValue) => {
        if (newValue) {
            helpers.setValue(newValue.toISOString());
        } else {
            helpers.setValue('');
        }
    };

    const configDatePicker = {
        ...props,
        sx: {width: "100%", background:'#fff', marginBottom: 0},
        value: field.value ? moment(field.value) : null, // Ensure the value is a moment object
        onChange: handleDateChange,
        minDate: minDate ? moment(minDate) : null, // Parse minDate to moment object if it exists
        maxDate: maxDate ? moment(maxDate) : null, // Parse maxDate to moment object if it exists
        renderInput: (params) => (
            <CustomInputField
                {...params}
                fullWidth={fullWidth}
                sx={{ padding: '10px 14px',...sx }}
                InputProps={InputProps}
            />
        ),
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box sx={{ width: fullWidth ? '100%' : sx.width }}>
                {label && (
                    <Box
                        component="label"
                        sx={{
                            color: theme.palette.customColors.g500,
                            marginBottom: '8px',
                            display: 'block',
                            fontSize: '0.875rem',
                            ...labelStyle,
                        }}
                    >
                        {label}
                    </Box>
                )}
                <DatePicker
                    {...configDatePicker}
                />
                {meta && meta.touched && meta.error && (
                    <CustomValidationText value={meta.error} />
                )}
            </Box>
        </LocalizationProvider>
    );
};

export default CustomDatePicker;
