import React from "react";
import ProfileInformation from "./ProfileInformation";
import StartupInformation from "./StartupInformation";
import { useFormik } from "formik";
import { AddStartup, FetchAllCountries, FetchAllSectors, FetchProfileDetails, FetchStartupDetails, SavePersonalInfo, SaveProfileImage, SaveStartupInfo, SendWhatsappOTP } from "../../api";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import CustomToast from "../../ui-components/CustomToast";
import ModalComponent from "../../ui-components/ModalComponent";
import Wrapper from "./Wrapper";
import { Box, CircularProgress, Modal } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useConfig } from "../../dynamicConfiguration/configContext";
import { isRequired, linkedInTestURL } from "../../utils";

const countryCodes = [
  { code: "+1", label: "US" },
  { code: "+91", label: "IN" },
];

const StartupOnboarding = () => {
  const { config } = useConfig()
  const { data: countries, isRefetching: isRefetchingCountries, isLoading: isLoadingCountries, refetch: refetchCountries } = FetchAllCountries({ search: '' })
  const { data: profileDetailsData, isRefetching: isProfileDetailsRefetching, isLoading: isProfileDetailsLoading, refetch: refetchProfileDetails } = FetchProfileDetails()
  const { data: startup_sectors, isRefetching: isRefetchingSectors, isLoading: isLoadingSectors, refetch: refetchSectors } = FetchAllSectors()
  let stepIndex = new URLSearchParams(window.location.search).get("page");
  const [searchParams, setSearchParams] = useSearchParams()
  const [progress, setProgress] = React.useState(1);
  const [currentStep, setCurrentStep] = React.useState(Number(stepIndex) || 1);
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    mutateAsync: AddStartupAsync,
    status: AddStartupStatus,
    error: AddStartupError,
    isLoading: isUpdating,
    data: AddStartupResponse,
  } = AddStartup({ setProgress });
  const {
    mutateAsync: SavePersonalInfoAsync,
    status: savePersonalInfoStatus,
    error: savePersonalInfoError,
    isLoading: isUpdatingsavePersonalInfo,
    data: savePersonalInfoResponse,
  } = SavePersonalInfo();
  const {
    mutateAsync: SaveProfileImageAsync,
    status: saveProfileImageStatus,
    error: saveProfileImageError,
    isLoading: isUpdatingsaveProfileImage,
    data: saveProfileImageResponse,
  } = SaveProfileImage();
  const {
    mutateAsync: SaveStartupInfoAsync,
    status: saveStartupInfoStatus,
    error: saveStartupInfoError,
    isLoading: isUpdatingsaveStartupInfo,
    data: saveStartupInfoResponse,
  } = SaveStartupInfo();
  const {
    mutateAsync: SendWhatsappOTPAsync,
    status: sendWhatsappOTPStatus,
    error: sendWhatsappOTPError,
    isLoading: isUpdatingSendWhatsappOTP,
    data: sendWhatsappOTPResponse,
  } = SendWhatsappOTP();

  const validationObj =
    currentStep == 1
      ? {
        title: isRequired(config?.validationFields, "title") ? Yup.string(config?.validationFields?.title?.error).required(config?.validationFields?.title?.error).trim() : Yup.string(),
        first_name: isRequired(config?.validationFields, "first_name") ? Yup.string(config?.validationFields?.first_name?.error).required(config?.validationFields?.first_name?.error).trim() : Yup.string(),
        last_name: isRequired(config?.validationFields, "last_name") ? Yup.string(config?.validationFields?.last_name?.error).required(config?.validationFields?.last_name?.error).trim() : Yup.string(),
        dd: isRequired(config?.validationFields, "dob") ?
          Yup.string()
            .required(config?.validationFields?.dob?.dateError)
            .matches(/^(0[1-9]|[12][0-9]|3[01])$/, config?.validationFields?.dob?.dateError)
          : Yup.string(),
        mm: isRequired(config?.validationFields, "dob") ?
          Yup.string()
            .required(config?.validationFields?.dob?.monthError)
            .matches(/^(0[1-9]|1[0-2])$/, config?.validationFields?.dob?.monthError)
          : Yup.string(),
        yyyy: isRequired(config?.validationFields, "dob") ?
          Yup.number()
            .required(config?.validationFields?.dob?.yearError)
            .min(1900, config?.validationFields?.dob?.yearError)
            .max(new Date().getFullYear(), config?.validationFields?.dob?.futureError)
          : Yup.number(),
      }
      : currentStep == 2
        ? {
          custom_image_url: isRequired(config?.validationFields, "profile_pic") ?
            Yup.string().when("avatar_selected", {
              // is: (avatar_selected) => {return avatar_selected == null},
              is: "",
              then: () => Yup.string(config?.validationFields?.profile_pic?.error),
              otherwise: () => Yup.string()
            })
            : Yup.string(),
          avatar_selected: isRequired(config?.validationFields, "profile_pic") ?
            Yup.string().when("custom_image_url", {
              is: (custom_image_url) => { return custom_image_url == null },
              then: () => Yup.string(config?.validationFields?.profile_pic?.error),
              otherwise: () => Yup.string()
            })
            : Yup.string()
        }
        : currentStep == 3
          ? {
            // city_id: Yup.string("Select a City")
            //   .required("City is required")
            //   .trim(),
            // state_id: Yup.string("Select a State")
            //   .required("State is required")
            //   .trim(),
          }
          : currentStep == 4
            ? {
              // startup_logo: Yup.mixed()
              //   .test("file-upload", "Please upload a startup logo", (value) => {
              //     return value !== null;
              //   })
              //   .required("Startup logo is required"),
              // startup_name: Yup.string("Enter Startup Name")
              //   .required("Startup name is required")
              //   .trim(),
              // entity_name: Yup.string("Enter Legal Entity Name")
              //   .required("Legal entity name is required")
              //   .trim(),
              // formation_date: Yup.string("Enter Formation Date")
              //   .required("Formation date is required")
              //   .trim(),
            }
            : currentStep == 5
              ? {
                // sector_id: Yup.string().required("Startup Sector is required").min(1),
                // sector_id: Yup.array().min(1, 'Please select at least one sector').max(1).required('Startup sector is required'),
              }
              : currentStep == 6
                ? {
                  // stage_id: Yup.string().required("Startup stage is required").min(1),
                }
                : currentStep == 7
                  ? {
                    // pitch_deck: Yup.mixed()
                    //   .test("file-upload", "Please upload pitch deck", (value) => {
                    //     return value !== null;
                    //   })
                    //   .test("file-size", "File size should not exceed 5MB", (value) => {
                    //     if (value && typeof value === 'object') {
                    //       return value.size <= 5 * 1024 * 1024; // 100MB in bytes
                    //     }
                    //     return true; // If value is not an object, skip size validation
                    //   })
                    //   .required("Pitch deck is required"),
                    // description: Yup.string().required('Startup overview is required')
                    //   .test(
                    //     'richText-not-empty',
                    //     'Startup overview is required',
                    //     value => {
                    //       // Check if the value is empty or just a new line
                    //       const strippedValue = value.replace(/<[^>]+>/g, '').trim();
                    //       return strippedValue !== '';
                    //     }
                    //   )

                  }
                  : currentStep == 8
                    ? {
                      // elevator_pitch: Yup.mixed()
                      //   .test("file-size", "File size should not exceed 100MB", (value) => {
                      //     if (value && typeof value === 'object') {
                      //       return value.size <= 100 * 1024 * 1024; // 100MB in bytes
                      //     }
                      //     return true; // If value is not an object, skip size validation
                      //   }).nullable(),
                      // problem_solving: Yup.string("Enter problem statement")
                      //   .required("Problem statement is required")
                      //   .trim(),
                    }
                    : currentStep == 9
                      ? {
                        // website_url: Yup.string("Enter WEBSITE URL")
                        //   .required("Website url is required")
                        //   .trim(),
                        // linkedin_url: Yup.string("Enter a LinkedIn URL")
                        //   .trim(),
                      }
                      :
                      currentStep == 10
                        ? {
                          // founder: Yup.object().shape({
                          //   name: Yup.string().required("Founder name is required"),
                          //   bio: Yup.string(),
                          //   title: Yup.string().required("Founder title is required"),
                          //   image: Yup.mixed()
                          //     .test("file-upload", "Please upload founder pic", (value) => {
                          //       return value !== null;
                          //     })
                          //     .required("Founder photo is required"),
                          //   linkedin_url: Yup.string().required("Founder linkedin url is required")
                          // })
                        } : currentStep == 11
                          ? {
                            // coFoundersArray: Yup.array().of(Yup.object().shape({
                            //   name: Yup.string().required("Co-founder name is required"),
                            //   linkedin_url: Yup.string().required("Co-founder linkedin url is required"),
                            //   title: Yup.string().required("Co-Founder title is required"),
                            //   // image: Yup.mixed()
                            //   //   .test("file-upload", "Please upload co-founder image", (value) => {
                            //   //     return value !== null;
                            //   //   })
                            //   //   .required("Co-founder Image is required"),
                            //   edit: Yup.boolean().notRequired(),
                            // }))
                          } : currentStep == 12 ? {
                            // referral_type: Yup.string("Select referral medium").required("Referral medium is required"),

                          } : {};

  const isValidDate = (day, month, year) => {
    const date = new Date(year, month - 1, day);
    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  };

  const nameValidations = {
    title: isRequired(config?.validationFields, "title") ? Yup.string(config?.validationFields?.title?.error).required(config?.validationFields?.title?.error).trim() : Yup.string(),
    first_name: isRequired(config?.validationFields, "first_name") ? Yup.string(config?.validationFields?.first_name?.error).required(config?.validationFields?.first_name?.error).trim() : Yup.string(),
    last_name: isRequired(config?.validationFields, "last_name") ? Yup.string(config?.validationFields?.last_name?.error).required(config?.validationFields?.last_name?.error).trim() : Yup.string(),
    dd: isRequired(config?.validationFields, "dob") ?
      Yup.string()
        .required(config?.validationFields?.dob?.dateError)
        .matches(/^(0[1-9]|[12][0-9]|3[01])$/, config?.validationFields?.dob?.dateError)
      : Yup.string(),
    mm: isRequired(config?.validationFields, "dob") ?
      Yup.string()
        .required(config?.validationFields?.dob?.monthError)
        .matches(/^(0[1-9]|1[0-2])$/, config?.validationFields?.dob?.monthError)
      : Yup.string(),
    yyyy: isRequired(config?.validationFields, "dob") ?
      Yup.number()
        .required(config?.validationFields?.dob?.yearError)
        .min(1900, config?.validationFields?.dob?.yearError)
        .max(new Date().getFullYear(), config?.validationFields?.dob?.futureError)
      : Yup.number(),
  }

  const profileImageValidations = {
    custom_image_url: Yup.mixed()
      .nullable(config?.validationFields?.profile_pic?.error)
      .test('file-or-url', config?.validationFields?.profile_pic?.error, function (value) {
        // If value is a file, check its type and size
        if (value && value instanceof File) {
          const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
          return validFileTypes.includes(value.type) && value.size <= 2000000; // File type & size check
        }
        // If value is a string, validate it as a URL
        if (typeof value === 'string') {
          return Yup.string().url().isValidSync(value); // URL validation
        }
        return true; // If no value, it's valid (nullable)
      }),
    avatar_selected: Yup.string().nullable(config?.validationFields?.profile_pic?.error)
  }

  const countryValidations = {
    country_id: isRequired(config?.validationFields, "country") ? Yup.string(config?.validationFields?.country?.error).required(config?.validationFields?.country?.error).trim() : Yup.string(),
  }

  const stateValidations = {
    state_id: isRequired(config?.validationFields, "state") ? Yup.string(config?.validationFields?.state?.error).required(config?.validationFields?.state?.error).trim() : Yup.string(),
    city_id: isRequired(config?.validationFields, "city") ? Yup.string(config?.validationFields?.city?.error).required(config?.validationFields?.city?.error).trim() : Yup.string(),
  }

  const sectorValidations = {
    sector_id: isRequired(config?.validationFields, "sectors") ? Yup.array().min(1, config?.validationFields?.sectors?.error).required(config?.validationFields?.sectors?.error) : Yup.array(),
  }

  const sizeValidations = {
    investment_size: isRequired(config?.validationFields, "size") ? Yup.string(config?.validationFields?.size?.error).required(config?.validationFields?.size?.error) : Yup.string(),
  }

  const stageValidations = {
    stage_id: isRequired(config?.validationFields, "stages") ? Yup.array().min(1, config?.validationFields?.stages?.error).required(config?.validationFields?.stages?.error) : Yup.array(),
  }

  const investmentCountValidations = {
    investment_count: isRequired(config?.validationFields, "investment_count") ? Yup.string(config?.validationFields?.investment_count?.error).required(config?.validationFields?.investment_count?.error).trim() : Yup.string(),
  }

  const investmentValidations = {
    startups: isRequired(config?.validationFields, "investments") ? Yup.array(
      Yup.object({
        startup_name: Yup.string(config?.validationFields?.startup_name?.error).required(config?.validationFields?.startup_name?.error).trim(),
        startup_url: Yup.string(config?.validationFields?.startup_website_url?.error).required(config?.validationFields?.startup_website_url?.error).trim()
      })
    ) : Yup.string(),
  }

  const whatsappValidations = {
    whatsapp_number: isRequired(config?.validationFields, "mobile_number") ? Yup.string(config?.validationFields?.mobile_number?.error).required(config?.validationFields?.mobile_number?.error).trim() : Yup.string(),
  }

  const linkedInValidations = {
    profile_url: isRequired(config?.validationFields, "linkedIn_profile_url") ? Yup.string(config?.validationFields?.linkedIn_profile_url?.error).required(config?.validationFields?.linkedIn_profile_url?.error).trim().matches(linkedInTestURL, config?.validationFields?.startup_website_url?.formaterror) : Yup.string(),
  }

  const getValidations = () => {
    let validations = Yup.object().shape({})
    switch (currentStep) {
      case 1: {
        validations = Yup.object().shape(nameValidations).test('is-valid-date', config?.validationFields?.dob?.error, function (value) {
          const { dd, mm, yyyy } = value || {};
          if (!isValidDate(parseInt(dd), parseInt(mm), yyyy)) {
            return false; // Invalid date
          }

          // Check if the date is in the future
          const inputDate = new Date(yyyy, mm - 1, dd);
          const today = new Date();
          console.log("inputDate", inputDate)
          today.setHours(0, 0, 0, 0); // Set to the start of the day
          return inputDate <= today; // Allow dates that are today or earlier
        });
        break
      }
      case 2: {
        validations = Yup.object().shape(profileImageValidations).test('at-least-one', config?.validationFields?.profile_pic?.error, function (values) {
          const { custom_image_url, avatar_selected } = values;
          return custom_image_url || avatar_selected;
        });
        break
      }
      case 3: {
        validations = Yup.object().shape(countryValidations)
        break
      }
      case 4: {
        validations = Yup.object().shape(stateValidations)
        break
      }
      case 5: {
        validations = Yup.object().shape(sectorValidations)
        break
      }
      case 6: {
        validations = Yup.object().shape(sizeValidations)
        break
      }
      case 7: {
        validations = Yup.object().shape(stageValidations)
        break
      }
      case 8: {
        validations = Yup.object().shape(investmentCountValidations)
        break
      }
      case 9: {
        validations = Yup.object().shape(investmentValidations)
        break
      }
      case 10: {
        validations = Yup.object().shape(whatsappValidations)
        break
      }
      case 11: {
        validations = Yup.object().shape(linkedInValidations)
        break
      }
    }
    console.log("validations", validations)
    return validations
  }

  // const validationSchema = Yup.object().shape({ ...validationObj })
  const formik = useFormik({
    initialValues: !isProfileDetailsLoading && !isLoadingCountries && profileDetailsData ? profileDetailsData.data : {
      // step 1
      first_name: '',
      last_name: '',
      title: '',
      dd: '',
      mm: '',
      yyyy: '',
      custom_image_url: null,
      avatar_selected: null,
      country_id: null,
      state_id: null,
      city_id: null,
      sector_id: [],
      investment_size: null,
      min: null,
      max: null,
      investment_amount_type: null,
      stage_id: [],
      investment_count: null,
      startups: [],
      enabled: false,
      whatsapp_number: null,
      profile_url: null,
      linkedin_public_profile: false,
      step: 1
    },
    validationSchema: getValidations,
    // validate: (values) => { console.log(values, "valueseferfe"); console.log(formik.errors, "err") },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    enableReinitialize: true,
    onSubmit: async (values, { setErrors }) => {
      const formData = new FormData();
      let request = {}
      let requestArray = []

      switch (currentStep) {
        case 1:
          let dob = null;
          if (values.mm != null && values.dd != null && values.yyyy && values.yyyy?.length != 0) {
            let combinedDateString = values.mm + "-" + values.dd + "-" + values.yyyy
            let combinedDate = moment(combinedDateString, "MM-DD-YYYY")
            // if (combinedDate.isAfter(new Date())) {
            //   setErrors({ yyyy: config?.validationFields?.dob?.futureError })
            // } else {
            //   setErrors(null)
            // }
            dob = combinedDate
          }
          request = {
            ...request, ...{
              title: values.title,
              first_name: values.first_name,
              last_name: values.last_name,
              dob: dob
            }
          }
          break;

        case 2:
          if (values.custom_image_url != null) {
            if (typeof values.custom_image_url != 'string') {
              formData.append("profile_picture", values.custom_image_url);
            }
          } else {
            formData.append("avatar_id", values.avatar_selected);
          }
          break;

        case 3:
          request = {
            ...request, ...{
              country_id: values.country_id,
            }
          }
          break;

        case 4:
          request = {
            ...request, ...{
              state_id: values.state_id,
              city_id: values.city_id,
            }
          }
          break;

        case 5:
          request = {
            ...request, ...{
              sector_ids: values.sector_id,
            }
          }
          break;

        case 6:
          request = {
            ...request, ...{
              investment_size: values.investment_size,
              min: values.min,
              max: values.max,
              investment_amount_type: values.investment_amount_type
            }
          }
          // formData.append("stage_id", values.stage_id);
          break;

        case 7:
          request = {
            ...request, ...{
              stage_ids: values.stage_id,
            }
          }
          break;

        case 8:
          request = {
            ...request, ...{
              investment_count: values.investment_count,
            }
          }
          break;

        case 9:
          let startupData = values?.startups
          startupData = startupData?.filter(item => item.startup_name?.trim()?.length > 0 && item.startup_url?.trim()?.length > 0)
          requestArray = [...startupData]
          break;

        case 10:
          request = { ...request, ...{ mobile_number: values.whatsapp_number } }
          break;

        case 11:
          request = {...request, ...{
            linkedin_profile_url: values?.profile_url,
            is_discoverd_publicly: values?.linkedin_public_profile
          }}
          break;
        default:
          break;
      }

      if (currentStep >= values?.step || values?.step == undefined) {
        formData.append('step', currentStep)
        if (currentStep != 9) {
          request = { ...request, ...{ step: currentStep } }
        }
      }
      if (currentStep == 2) {
        SaveProfileImageAsync(formData).catch(err => {
          console.log(err?.response?.data?.message, "response herer")

          toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
            duration: 4000,
            position: "top-right",
            style: {
              padding: "15px 30px",
            },
          });
        });
      } else if (currentStep == 9) {
        SaveStartupInfoAsync(requestArray).catch(err => {
          console.log(err?.response?.data?.message, "response herer")

          toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
            duration: 4000,
            position: "top-right",
            style: {
              padding: "15px 30px",
            },
          });
        });
      } else if (currentStep == 10) {
        SendWhatsappOTPAsync(request).catch(err => {
          console.log(err?.response?.data?.message, "response herer")

          toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
            duration: 4000,
            position: "top-right",
            style: {
              padding: "15px 30px",
            },
          });
        });
      } else {
        SavePersonalInfoAsync(request).catch(err => {
          console.log(err?.response?.data?.message, "response herer")

          toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
            duration: 4000,
            position: "top-right",
            style: {
              padding: "15px 30px",
            },
          });
        });
      }
    },
  });

  React.useEffect(() => {
    // console.log("savePersonalInfoResponse", savePersonalInfoResponse)
    if (savePersonalInfoResponse && savePersonalInfoResponse?.data?.statusCode == 200) {
      refetchProfileDetails()
      if (currentStep == 11) {
        // console.log(savePersonalInfoResponse,"res")
        // Cookies.set("is_profile_complete", savePersonalInfoResponse?.data?.data?.is_profile_completed ? savePersonalInfoResponse?.data?.data?.is_profile_completed : false);
        Cookies.set("is_profile_complete", true);
        navigate("/welcome");
      }
      else {
        // refetchStartupDetails()

        setCurrentStep(currentStep + 1);
        setSearchParams({ page: currentStep + 1 });
      }
    }
    window.document.getElementsByClassName("scrollbar-container")[0]?.scroll(0, 0);
  }, [savePersonalInfoResponse?.data?.statusCode]);

  React.useEffect(() => {
    // console.log("saveProfileImageResponse", saveProfileImageResponse)
    if (saveProfileImageResponse && saveProfileImageResponse?.data?.statusCode == 200) {
      refetchProfileDetails()
      if (currentStep == 11) {
        // console.log(saveProfileImageResponse,"res")
        Cookies.set("is_profile_complete", saveProfileImageResponse?.data?.data?.is_profile_completed);
        navigate("/welcome");
      }
      else {
        // refetchStartupDetails()

        setCurrentStep(currentStep + 1);
        setSearchParams({ page: currentStep + 1 });
      }
    }
    window.document.getElementsByClassName("scrollbar-container")[0]?.scroll(0, 0);
  }, [saveProfileImageResponse?.data?.statusCode]);

  React.useEffect(() => {
    // console.log("saveProfileImageResponse", saveProfileImageResponse)
    if (saveStartupInfoResponse && saveStartupInfoResponse?.data?.statusCode == 200) {
      refetchProfileDetails()
      setCurrentStep(currentStep + 1);
      setSearchParams({ page: currentStep + 1 });
    }
    window.document.getElementsByClassName("scrollbar-container")[0]?.scroll(0, 0);
  }, [saveStartupInfoResponse?.data?.statusCode]);

  // React.useEffect(() => {
  //   if (sendWhatsappOTPResponse && sendWhatsappOTPResponse?.data?.statusCode == 200) {
      // refetchProfileDetails()
      // setCurrentStep(currentStep + 1);
      // setSearchParams({ page: currentStep + 1 });
  //   }
  //   window.document.getElementsByClassName("scrollbar-container")[0]?.scroll(0, 0);
  // }, [sendWhatsappOTPResponse?.data?.statusCode]);

  return (
    <>
      <Modal open={isProfileDetailsLoading || isLoadingCountries || isLoadingSectors}>
        <Wrapper className="skeleton" style={{
          display: 'grid', height: '100vh',
          width: "100vw",
          placeItems: 'center'
        }}>
          {/* <CircularProgress sx={{ color: theme?.palette.customColors.b300 }} /> */}
        </Wrapper>
      </Modal>
      {currentStep <= 11 ?
        <ProfileInformation
          progress={progress}
          setProgress={setProgress}
          formik={formik}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          isLoading={isProfileDetailsLoading || isUpdatingsaveStartupInfo}
          isLoadingDetails={isProfileDetailsLoading}
          isUpdating={isUpdatingsavePersonalInfo || isUpdatingsaveProfileImage}
          resend = {SendWhatsappOTPAsync}
          refetchProfileDetails = {refetchProfileDetails}
        /> :
        <StartupInformation
          progress={progress}
          setProgress={setProgress}
          formik={formik}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          isLoading={isProfileDetailsLoading || isUpdatingSendWhatsappOTP}
          isUpdating={isUpdatingsavePersonalInfo || isUpdatingsaveProfileImage || isUpdatingsaveStartupInfo}
        />
      }
    </>
  );
};

export default StartupOnboarding;
