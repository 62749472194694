import React from 'react'
import { InputAdornment } from '@mui/material'
import CustomInputField from './formik/CustomInputField'
import theme from '../themes/theme'

const CustomUrlField = ({label ,placeholder, ...props}) => {
  return (
        <CustomInputField 
            label={label}
            fullWidth
            placeholder={placeholder} 
            type="text"
            InputProps={{
              startAdornment: <InputAdornment position="start" sx={{
                    padding: '0px 13px',
                    height: '49px',
                    maxHeight:'none',
                    borderRight: `1px solid ${theme.palette.customColors.g75}`,
                    marginRight: '0px',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop:'0px!important'
                  }}>
                 https://
                </InputAdornment>
            }}
            {...props}
            />
  )
}

export default CustomUrlField
