import React from 'react'
import video from "../assets/sample-5s.mp4"
import Image from '../assets/pitchdeck.png'

const CustomPDFThumbnail = ({ type, height = 300 }) => {
    return (
        <div style={{ width: '100%', minHeight: 50, display: "flex", alignItems: "center", textAlign: 'center', justifyContent: 'center', borderRadius: '8px', overflow: 'hidden', background: '#140000' }}>
            {type === "video" ?
                <video controls width={"100%"} height={height}>
                    <source src={video}></source>
                </video>
                : <img src={Image} width={"100%"} height={height} />}
        </div>
    )
}

export default CustomPDFThumbnail