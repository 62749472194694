import { useTheme } from '@emotion/react';
import { Avatar, Box, Chip, Grid, Typography, useMediaQuery, Checkbox, Select, Pagination, MenuItem } from '@mui/material';
import React from 'react';
import { useTable } from 'react-table';
import PerfectScrollbar from "react-perfect-scrollbar";
import { containerRef, getFileCategory } from "../utils";
import { useNavigate } from 'react-router-dom';
import { WHITEBG } from './CustomButton';
import { ReactComponent as PreviousIcon } from "../assets/table-previous.svg";
import { ReactComponent as DownIcon } from "../assets/table-chevron-down.svg";
import { ReactComponent as NextIcon } from "../assets/table-next.svg";

function stringAvatar(name) {
    if (name && name?.includes(" ")) {
        return {
            sx: {
                bgcolor: "#F9F5FF",
                color: "#3538CD",
                width: "40px",
                height: "40px",
                fontSize: '1rem'
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    } else if (name && !(name?.includes(" "))) {
        return {
            sx: {
                bgcolor: "#F9F5FF",
                color: "#3538CD",
                width: "40px",
                height: "40px",
                fontSize: '1rem'
            },
            children: name[0],
        };
    }
    else {
        return {
            sx: {
                bgcolor: "#F9F5FF",
                color: "#3538CD",
                width: "40px",
                height: "40px",
                fontSize: '1rem'
            },
            children: '',
        };
    }
}

const AsyncTableListing = ({ columns, data = [], title, actions: Actions, rowActions: RowActions, chipText, emptyTitle = "No Data Available", backgroundColor = '#fff', page, setPage, limit, setLimit, pagination = false, count }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const canPreviousPage = page > 1;
    const canNextPage = page < count;

    const previousPage = () => {
        if (canPreviousPage) {
            setPage(page - 1)
        }
        else{

        }
    }

    const nextPage = () => {
        if (canNextPage) {
            setPage(page + 1)
        }
        else{

        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows, // Full data without pagination
        prepareRow,
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 },
    });

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const DocumentIcon = ({ row, value }) => {
        const theme = useTheme();
        return <Box width={isSmallScreen ? 35 : 40} height={isSmallScreen ? 35 : 40} bgcolor={theme?.palette?.customColors?.indigo100} borderRadius={"50%"} display={"flex"} alignItems="center" justifyContent={"center"}>
            <img src={getFileCategory(value)} width={20} height={20} />
        </Box>
    };

    return (
        <>
            <Grid container className='table-container' border={`1px solid ${theme.palette.customColors.gray200}`} borderRadius={"8px"} bgcolor={backgroundColor}
                maxHeight={"60vh"}
                height={"100%"} overflow={"hidden"}>
                {title && <Grid container item xs={12} display={"flex"} justifyContent={"space-between"}
                    padding={"1rem 1.5rem"} alignItems={"center"}>
                    <Box display={"flex"} alignItems={"center"} gap={"0.5rem"}>
                        <Typography color={theme.palette.customColors.g300} sx={{ fontSize: '18px', fontWeight: 500, lineHeight: "28px", textAlign: 'left' }}>
                            {title}
                        </Typography>
                        {chipText && <Chip label={chipText} size='small' sx={{ padding: '0.125rem 0.5rem !important', color: "#6941C6", bgcolor: '#F9F5FF', "& > .MuiChip-label": { padding: 0 } }} />}
                    </Box>
                    {Actions && <Actions />}
                </Grid>}

                <PerfectScrollbar containerRef={containerRef} style={{ height: '100%', maxHeight: matchesUpMd ? '44vh' : '40vh', width: '100%' }}>
                    <table style={{ width: '100%' }} {...getTableProps()}>
                        <thead style={{ position: 'sticky', zIndex: 1000, top: 0, backgroundColor: theme.palette.background.default }}>
                            {headerGroups?.map(headerGroup => (
                                <tr
                                    key={headerGroup.id}
                                    style={{
                                        fontSize: "12px", fontWeight: 500, lineHeight: '18px', textAlign: "left",
                                        color: theme.palette.customColors.g100
                                    }}
                                    {...headerGroup.getHeaderGroupProps()}
                                >
                                    {headerGroup?.headers?.map((column, index) => {
                                        if (column?.id === 'checkbox') {
                                            return (
                                                <th style={{ minWidth: column?.minWidth }} key={column.id} {...column.getHeaderProps()}><Checkbox style={{ height: 20, width: 20, color: "#7c4dff" }} /></th>
                                            );
                                        } else if (column?.id === 'report_actions') {
                                            return (
                                                <th style={{ minWidth: column?.minWidth }} key={column.id} {...column.getHeaderProps()}>
                                                    {data?.length > 0 && <Typography
                                                        onClick={() => { navigate(`/reports/edit_report/${data[0]?.report_id}`) }}
                                                        sx={{
                                                            textAlign: "center",
                                                            color: "#0075FF",
                                                            fontSize: '0.75rem',
                                                            cursor: 'pointer'
                                                        }}
                                                    >View All</Typography>}
                                                </th>
                                            );
                                        }
                                        return (
                                            <th style={{ minWidth: column?.minWidth }} key={column.id} {...column.getHeaderProps()}>{column.render('Header')}</th>
                                        );
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows?.length > 0 && rows?.map((row, idx) => {
                                prepareRow(row);
                                return (
                                    <tr key={row.id} {...row.getRowProps()}>
                                        {row?.cells?.map((cell, index) => {
                                            if (cell.column?.id === 'checkbox') {
                                                return (
                                                    <td key={index} {...cell.getCellProps()}><Checkbox style={{ height: 20, width: 20, color: "#7c4dff" }} sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            borderRadius: '8px',
                                                        }
                                                    }} /></td>
                                                );
                                            }
                                            if (cell.column.id === "name") {
                                                return (
                                                    <td key={index} {...cell.getCellProps()}>
                                                        <Box sx={{
                                                            display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                        }}>
                                                            <Avatar {...stringAvatar(cell.value)} /> {cell.render('Cell')}
                                                        </Box>
                                                    </td>
                                                );
                                            } else if (cell.column.id === "document_name") {
                                                const extension = cell?.row?.original?.filename?.split(".")?.pop();
                                                return (
                                                    <td key={index} {...cell.getCellProps()}>
                                                        <Box sx={{
                                                            display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                        }}>
                                                            <DocumentIcon value={String(cell.value)?.substring(0, cell.column?.trim) + "." + extension} row={row} /> {String(cell.value)?.substring(0, 50) + "." + extension}
                                                        </Box>
                                                    </td>
                                                );
                                            } else if (cell.column.id === "actions" || cell.column.id === "report_actions") {
                                                return (
                                                    <td key={index} {...cell.getCellProps()}>
                                                        <Box sx={{
                                                            display: 'flex', alignItems: 'center', color: theme.palette.customColors.g500, fontSize: "14px", fontWeight: 500, lineHeight: '20px', gap: '12px'
                                                        }}>
                                                            <RowActions cell={cell} row={row} />
                                                        </Box>
                                                    </td>
                                                );
                                            }
                                            return (
                                                <td key={index} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            {rows?.length === 0 && (
                                <tr>
                                    <td colSpan={columns?.length > 0 ? columns?.length : 1} align='center' style={{ textAlign: 'center' }}>{emptyTitle}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </PerfectScrollbar>
                {pagination && (
                    <Grid container item xs={12} sx={{
                        padding: '12px 24px 16px 24px', display: 'grid', gridTemplateAreas: isSmallScreen ? `"previous page next" "number number number"` : `"previous number page span next"`, gridTemplateColumns: isSmallScreen ? '1fr 2fr 1fr' : '1fr 1fr 10fr 1fr 1fr',
                        gridTemplateRows: isSmallScreen ? '1fr 1fr' : '1fr', borderTop: `1px solid ${theme.palette.customColors.gray200}`, position: 'sticky', bottom: 0, background: theme.palette.customColors.whiteBG,
                        rowGap: 1, columnGap: 1, alignItems: 'center'
                    }}>
                        <Grid item display={"flex"} gap={"0.5rem"} gridArea={'previous'}>
                            <WHITEBG onClick={() => previousPage()} disabled={!canPreviousPage}
                                sx={{
                                    minWidth: "0 !important",
                                    border: `1px solid #D0D5DD !important`,
                                    padding: "8px 14px",
                                    '& > .MuiButton-icon.MuiButton-startIcon': { marginRight: isSmallScreen ? 0 : '8px', marginLeft: isSmallScreen ? 0 : '-4px' }
                                }}
                                startIcon={<PreviousIcon stroke={!canPreviousPage ? '#D0D5DD' : '#344054'} />}
                            >
                                {isSmallScreen ? '' : "Previous"}
                            </WHITEBG>
                        </Grid>
                        <Grid item gridArea={'number'} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                            <Select
                                value={limit}
                                onChange={(event) => {
                                    setLimit(event.target.value);
                                    setPage(1);
                                }}
                                size="small"
                                IconComponent={DownIcon}
                                sx={{
                                    minWidth: 150,  // Adjust the width as needed
                                    borderRadius: '8px',
                                    border: '1px solid #D0D5DD',  // Border similar to the one in the image
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    color: '#344054',  // Text color
                                    '& .MuiSelect-select': {
                                        padding: '8px 12px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        marginTop: 0,
                                        color: '#344054',  // Arrow icon color
                                    },
                                    '& > .MuiSelect-icon': {
                                        top: '25%'
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',  // Remove the default border
                                    },
                                }}
                            >
                                {[10, 20, 25, 30, 40, 50]?.map((size) => (
                                    <MenuItem key={size} value={size}>
                                        {size} Per page
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item display={"flex"} justifyContent={"center"} gridArea={'page'}>
                            <Pagination
                                count={Math.ceil(count / limit)}
                                page={page}
                                onChange={(e, value) => setPage(value)}
                                shape="rounded"
                                hideNextButton
                                hidePrevButton
                                sx={{
                                    '& .MuiPaginationItem-root': {
                                        color: '#6c757d', // Inactive page color
                                        fontWeight: '500',
                                    },
                                    '& .MuiPaginationItem-root.Mui-selected': {
                                        backgroundColor: 'rgba(138, 75, 244, 0.1)', // Light purple background
                                        color: '#8A4BF4', // Active page color
                                    },
                                    '& .MuiPaginationItem-ellipsis': {
                                        color: '#6c757d', // Ellipsis color
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item gridArea={'number'} display={"flex"} alignItems={"center"} justifyContent={"center"}></Grid>
                        <Grid item display={"flex"} flexDirection={"row-reverse"} gridArea={'next'}>
                            <WHITEBG onClick={() => nextPage()} disabled={!canNextPage}
                                sx={{
                                    minWidth: "0 !important",
                                    border: `1px solid #D0D5DD !important`,
                                    padding: "8px 14px",
                                    '& > .MuiButton-icon.MuiButton-endIcon': { marginRight: isSmallScreen ? 0 : '-4px', marginLeft: isSmallScreen ? 0 : '8px' }
                                }}
                                endIcon={<NextIcon stroke={!canNextPage ? '#D0D5DD' : '#344054'} />}
                            >
                                {isSmallScreen ? '' : 'Next'}
                            </WHITEBG>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default AsyncTableListing;
