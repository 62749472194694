import React from 'react'
import BreadcrumbHeader from '../../layout/BreadcrumbsHeader'
import ThemeBGWrapper from '../../ui-components/ThemeBGWrapper'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { Box, Card, CardContent, CardHeader, Grid, IconButton, LinearProgress, Menu, MenuItem, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { B300 } from '../../ui-components/CustomButton'
import { Add } from '@mui/icons-material'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ReactComponent as MoreIcon } from "../../assets/rounds_icons/more_icon.svg"
import { ReactComponent as SecurityIcon } from "../../assets/rounds_icons/security_icon.svg"
import { containerRef, handleDateValues } from '../../utils'
import { DeleteReportByID, FetchAllDocumentTypes, FetchAllReports } from '../../api'
import NoReports from './NoReports'
import { useSelector } from 'react-redux'
import moment from 'moment'
import toast from 'react-hot-toast'
import CustomToast from '../../ui-components/CustomToast'
import DeleteConfirmationDialog from '../../ui-components/popups/DeleteConfirmationPopup'
import AsyncTableListing from '../../ui-components/AsyncTableListing'

const Reports = () => {
    const theme = useTheme()
    const isNotLargeScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate()
    const startupDetails = useSelector((state) => state?.startupDetails?.data);
    const { data: reportsData, isLoading: isLoadingReports, refetch: refetchReportsData } = FetchAllReports(startupDetails?._id);
    const { data: document_types, isRefetching: isRefetchingDocumentTypes, isLoading: isLoadingDocumentTypes, refetch: refetchDocumentTypes } = FetchAllDocumentTypes();
    // console.log(document_types, "document_types")
    const {
        mutateAsync: deleteReportByIDAsync,
        isLoading: isdeletingReportByID,
        status: deleteReportByIDStatus,
        error: deleteReportByIDError,
        data: deleteReportByIDResponse
    } = DeleteReportByID();

    // console.log(reportsData, "reportsData") 

    const columns = React.useMemo(
        () => [
            { Header: 'Document Name', accessor: 'document_name', minWidth: 200, trim: 30 },
            { Header: 'Document Type', accessor: 'type_info', minWidth: 100 },
            {
                Header: "", accessor: 'report_actions', minWidth: 150
            },
        ],
        []
    );

    const documents = [
        {
            filename: 'Tech requirements.pdf',
            type: 'MIS Reports',
        },
        {
            filename: 'Tech requirements.pdf',
            type: 'MIS Reports',
        }
    ]

    const ReportActions = () => {
        return (
            <B300 onClick={() => navigate("/reports/create_report")} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 600, minWidth: isSmallScreen ? "0" : '64px', '& > .MuiButton-icon.MuiButton-startIcon': { marginRight: isSmallScreen ? 0 : '8px', marginLeft: isSmallScreen ? 0 : '-4px' } }} startIcon={<Add fontSize='1.25rem' />}>
                {!isSmallScreen ? "Add Report" : ''}
            </B300>
        )
    }

    const rowActions = ({ row, cell }) => {
        // console.log(row,cell);
        return <Box width={"100%"}>
            <Typography sx={{ cursor: 'pointer' }} onClick={() => { window.open("https://storage.googleapis.com/backrr-saas-dev-environment/reports/" + row?.original?.filename) }} textAlign={"center"} color={"#0075FF"} fontSize={"0.875rem"}>
                View Document
            </Typography>
        </Box>
    }


    const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
    const [selectedID, setSelectedID] = React.useState(null);

    const handleOpenDeletePopup = (currentID) => {
        setOpenDeletePopup(true);
        setSelectedID(currentID);
    }

    const handleCloseDeletePopup = () => {
        setOpenDeletePopup(false);
        setSelectedID(null);
    }

    const handleDeletion = () => {
        deleteReportByIDAsync({ document_id: selectedID }, {
            onSuccess: (succ) => {
                // console.log(succ, 'succ')
                toast.success(
                    <CustomToast message={"Report Deleted!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                    {
                        duration: 2000,
                        position: "top-right",
                    }
                );
                refetchReportsData();
            },
            onError: (err) => {
                toast.error(
                    <CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                    {
                        duration: 2000,
                        position: "top-right",
                    }
                );
            }
        });
        handleCloseDeletePopup();
    }

    const ReportCard = ({ data: cardData, key: index }) => {
        // popper more menu
        const [currentIndex, setCurrentIndex] = React.useState(index || null);
        const [anchorEl, setAnchorEl] = React.useState(null);
        const openMenu = Boolean(anchorEl);
        const handleClickMenu = (event, key) => {
            setAnchorEl(event.currentTarget);
            setCurrentIndex(key)
            // console.log(event.currentTarget,"re")
        };
        const handleCloseMenu = (event) => {
            // console.log(event,"event")
            setAnchorEl(null);
            setCurrentIndex(null);
        };

        const documentsWithTypes = cardData?.documents?.map((item, index) => ({
            ...item,
            type_info: document_types?.find(item => item?.value === cardData?.documents[index]?.type_id)?.name
        })) || []

        return (
            <Grid key={index} item xs={12} md={5.8} lg={5.9}>
                <Card sx={{ borderRadius: "10px", border: `1px solid ${theme.palette.customColors.g75}`, overflow: 'hidden', boxShadow: 'none' }}>
                    <CardHeader
                        sx={{ background: "#E2F0FF", padding: "6px 1rem", "& > .MuiCardHeader-avatar": { maxWidth: "100%" } }}
                        avatar={
                            <>
                                <Typography color={"#0075FF"} fontWeight={700} fontSize={"0.75rem"} width={"max-content"} borderRadius={"4px"}>
                                    {cardData?.formatted_from_date && cardData?.formatted_from_date ? String(`${cardData?.formatted_from_date} - ${cardData?.formatted_to_date}`) : "No Duration"}
                                </Typography>
                            </>
                        }
                    />
                    <CardContent sx={{ padding: "0 !important" }}>
                        <Grid item xs={12} bgcolor={"#FFFAF5"} display={"flex"} justifyContent={"space-between"} p={2} borderBottom={`1px solid ${theme.palette.customColors.g75}`}>
                            <Box display={"flex"} sx={{ gap: 1, alignItems: "center" }} maxWidth="100%">
                                <Typography onClick={() => navigate(`/reports/edit_report/${cardData?._id}`)} fontWeight={700} textOverflow={'ellipsis'} noWrap width="100%" fontSize={"1.25rem"} color={theme.palette.customColors.g300} sx={{ cursor: 'pointer' }}>
                                    {/* {cardData?.report_name && cardData?.report_name != "" ? cardData?.report_name : '-'} */}
                                    {cardData?.name && cardData?.name != "" ? cardData?.name : '-'}
                                </Typography>
                            </Box>
                            <Box display={"flex"} gap={1}>
                                <IconButton sx={{ padding: 0 }} onClick={(e) => handleClickMenu(e, index)} aria-label="more">
                                    <MoreIcon />
                                </IconButton>
                                {index === currentIndex && <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={openMenu}
                                    aria-controls={openMenu ? 'fade-menu' : 'fade-menu'}
                                    aria-haspopup="true"
                                    aria-expanded={openMenu ? 'true' : 'false'}
                                    onClose={handleCloseMenu}
                                    onClick={handleCloseMenu}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            padding: "12px",
                                            borderRadius: "8px",
                                            "& > ul.MuiList-root": { padding: "0 !important" },
                                            bgcolor: theme.palette.customColors.g400,
                                            color: theme.palette.customColors.whiteBG,
                                            '&::before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 11,
                                                width: 10,
                                                height: 10,
                                                bgcolor: theme.palette.customColors.g900,
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => navigate(`/reports/edit_report/${cardData?._id}`)}>
                                        Edit Report
                                    </MenuItem>
                                    <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => navigate(`/reports/edit_report/${cardData?._id}?page=investors`)}>
                                        Send Report
                                    </MenuItem>
                                    <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => {
                                        handleOpenDeletePopup(cardData?._id)
                                    }}>
                                        Delete
                                    </MenuItem>
                                </Menu>}
                            </Box>
                        </Grid>
                        <Grid item xs={12} padding={2}>
                            <Typography sx={{ padding: '1rem', border: `1px solid ${theme.palette.customColors.g50}`, color: theme.palette.customColors.g200, borderRadius: '8px', fontSize: "0.875rem", lineHeight: '21px' }}>
                                {cardData?.overview && String(cardData?.overview)?.trim() != "" ? cardData?.overview : '-'}
                            </Typography>
                            <Grid item container xs={12} display={"grid"} pt={2}>
                                <AsyncTableListing columns={columns} data={documentsWithTypes?.slice(0, 2)} rowActions={rowActions} pagination={false} emptyTitle='No Documents Attached' />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        )
    }

    return (
        <>
            <DeleteConfirmationDialog open={openDeletePopup} handleClose={handleCloseDeletePopup} handleSubmission={handleDeletion} subheading={"Are you sure you want to delete this report?"} />
            <ThemeBGWrapper bgColor={theme.palette.customColors.indigo25}>
                <BreadcrumbHeader title="Reports" breadcrumbTitle='Reports' customBackgroundColor={theme.palette.customColors.indigo25} Actions={ReportActions} />
                {
                    isLoadingReports ?
                        <PerfectScrollbar
                        containerRef={containerRef}
                            style={{ height: "calc(100vh - 120px)" }}>

                            <Grid container xs={12} padding={"2rem"} paddingTop={0} justifyContent={"space-between"} rowGap={4} columnGap={2}>
                                <Grid className="skeleton" item xs={12} md={5.8} lg={5.9} sx={{ minHeight: 380, width: '100%', borderRadius: '10px' }}>

                                </Grid>
                                <Grid className="skeleton" item xs={12} md={5.8} lg={5.9} sx={{ minHeight: 380, width: '100%', borderRadius: '10px' }}>

                                </Grid>
                                <Grid className="skeleton" item xs={12} md={5.8} lg={5.9} sx={{ minHeight: 380, width: '100%', borderRadius: '10px' }}>

                                </Grid>
                                <Grid className="skeleton" item xs={12} md={5.8} lg={5.9} sx={{ minHeight: 380, width: '100%', borderRadius: '10px' }}>

                                </Grid>
                            </Grid>
                        </PerfectScrollbar>
                        :
                        <>
                            {reportsData && reportsData?.length > 0 &&
                                <PerfectScrollbar
                                containerRef={containerRef}
                                    style={{ height: "calc(100vh - 120px)" }}>
                                    <Grid container xs={12} padding={"2rem"} paddingTop={isNotLargeScreen ? 0 : 0} gap={2} justifyContent={"space-between"}>
                                        {
                                            reportsData?.map((report, index) => <ReportCard data={report} key={index} />)
                                        }
                                    </Grid>

                                </PerfectScrollbar>
                            }
                            {(reportsData?.length === 0 || reportsData === undefined) && <NoReports />}
                        </>}
            </ThemeBGWrapper>
        </>
    )
}

export default Reports