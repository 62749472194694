// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAcjfMHrU_r_9MIBNeajgaJftK6vAKXnXU",
    authDomain: "backrbase.firebaseapp.com",
    projectId: "backrbase",
    storageBucket: "backrbase.appspot.com",
    messagingSenderId: "847284243710",
    appId: "1:847284243710:web:469ed57501d82e32f4e9af",
    measurementId: "G-FS83CTH077"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export { auth, provider, signInWithPopup, signOut };
