import React from "react";
import BGImage from "../assets/404 Error.gif";
import { Grid, Typography } from "@mui/material";
import { B300 } from "./CustomButton";
import ThemeBGWrapper from "./ThemeBGWrapper";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

const Error404 = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  return (
    <ThemeBGWrapper bgColor={theme.palette.customColors.indigo25}>
      <Grid
        xs={12}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={"column"}
        borderRadius={"24px 0px 0px"}
        overflow={"hidden"}
        height={"100%"}
      >
        <Grid item>
          <img src={BGImage} style={{ maxHeight: "600px", aspectRatio: 1, objectFit: 'contain', mixBlendMode: "multiply" }} />
        </Grid>
        <Grid item position={"absolute"} bottom={120} display="grid" gap={2}>
          <Typography variant="h1" color="#3538CD" fontWeight={700}>O-o-oh! Page not found!</Typography>
          {/* </Grid>
      <Grid item> */}
          <B300 onClick={() => navigate("/")}>Go to Overview</B300>
        </Grid>
      </Grid>
    </ThemeBGWrapper>
  );
};

export default Error404;
