import React, { useEffect } from "react";
import axios from "axios";
import { WHITEBG } from "../../ui-components/CustomButton";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import linkedInIcon from '../../assets/linkedin_icon.png'
import Cookies from "js-cookie"

const authURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86af1r2uxiyf5z&redirect_uri=${encodeURIComponent(
  `https://startup.backrr.in/callback`
)}&state=1234567890&scope=email%20profile%20openid`;

const client_id = "86af1r2uxiyf5z"
const client_ = "7WZ2QXTwx992NOAF"

const LinkedInAuth = ({ userData, setUserData, signin, loginMethod, setLoginMethod, isLoading }) => {
  const theme = useTheme();

  // Function to handle the login process
  const navigate = useNavigate();

  // Function to handle the login process
  const handleLogin = () => {
    // Redirect to LinkedIn's authorization URL
    // window.open(authURL);
    // Optionally, suggest opening in incognito/private mode
    // Note: This is just a suggestion; actual behavior depends on the user's browser settings
    // window.open(
    //   `chrome-extension://klbibkeccnjlkjkiokjodocebajanakg#incognito/${authURL}`,
    //   "_blank"
    // );
  };

  // Effect hook to handle the callback from LinkedIn
  useEffect(() => {

    // console.log(window.location.host,"host")

    // window.location.href= "https://linkedin.com/m/logout"
    let urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const fetchData = async () => {
      // console.log(code, "code");
      Cookies.set("code", code, { path: window.location.host })
      Cookies.remove('la_id')
      setLoginMethod("linkedin")
      try {
        const response = await axios
          .post("https://www.linkedin.com/oauth/v2/accessToken", null, {
            headers:{
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': "*"
            },
            params: {
              grant_type: "authorization_code",
              code: Cookies.get("code"),
              redirect_uri: `https://startup.backrr.in/callback`,
              client_id: "86af1r2uxiyf5z",
              client_secret: "7WZ2QXTwx992NOAF",
            },
          })
          .then((response) => {
            // const value = Cookies.get("li_at");
            // console.log(value,"value")
            // console.log(response,"red")
            const user = jwtDecode(response.data.id_token);
            signin(
              {
                // data: {
                "email": user?.email,
                "devices":
                {
                  "device_token": `${Math.random() * 10}`,
                  "device_type": "web"
                }
                ,
                "last_login_ip": `${window.location.host}`,
                "auth_provider": "linkedin"
                // }
              },
              {
                onSuccess: (response) => {
                  // console.log(response?.data?.data?.userData?.token, "loginViaEmailResponse")
                  Cookies.set("jwt", response?.data?.data?.userData?.token)
                  if (Cookies.get("is_profile_complete")) 
                    navigate("/");
                else{
                    navigate("/thank-you");
                }
                }
              }
            )
            setUserData(user);
            // console.log(user, "user");
            Cookies.set("email", user.email)
            Cookies.set("name", user.name)
          })

        // const accessToken = response.data.access_token;
        // const user = jwt_decode(response.data.access_token);
        // console.log(user,"user"); // Use the access token as needed;

        // navigate("/"); // Redirect to dashboard or protected area
      } catch (error) {
        console.error(error);
        navigate("/login"); // Handle error
      }
    };

    if (!code) {
      console.error("No authorization code found");
      navigate("/"); // Redirect back to home or login page
      return;
    }

    else {
      fetchData();
    }


  }, [navigate]); // Depend on navigate so the effect runs when the component mounts
  return (
    <>
      {!window.location.pathname.includes("/callback") && (
        <>
          <WHITEBG
            sx={{ border: `1px solid ${theme.palette.customColors.g75}`, height: '55px' }}
            // href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86af1r2uxiyf5z&redirect_uri=${encodeURIComponent(
            //   `${window.location.host}/callback`
            // )}&state=1234567890&scope=email%20profile%20openid`}
            onClick={() => { window.location.href = authURL }}
            fullWidth
          >
            {isLoading && loginMethod == "linkedin" ? <CircularProgress sx={{ color: "black", marginInline: 'auto' }} size={'1.4rem'} /> : <Grid
              display={"flex"}
              gap={1}
              flexDirection={"row"}
              alignItems={"center"}
            >
              <img src={linkedInIcon} width={24} height={24} />
              <Typography
                variant="caption"
                sx={{ textTransform: "none", fontWeight: 500, fontSize: '1rem' }}
              >
                Login via LinkedIn
              </Typography>
            </Grid>}
          </WHITEBG>
        </>
      )}
      {window.location.pathname.includes("/callback") && <WHITEBG sx={{ border: `1px solid ${theme.palette.customColors.g75}`, height: "100%" }} fullWidth ><CircularProgress sx={{ color: "black", marginInline: 'auto' }} size={'1.4rem'} /></WHITEBG>}
    </>
  );
};

export default LinkedInAuth;
