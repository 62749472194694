import { Box, Button, CircularProgress, Divider, Grid, IconButton, InputLabel, LinearProgress, Modal, Paper, Stack, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import Wrapper from './Wrapper'
import bgImage from '../../assets/login_screen_image.png'
import linkedInIcon from '../../assets/linkedin_icon.png'
import { B300 as CustomButton, BLUEBORDER } from '../../ui-components/CustomButton'
import { useNavigate } from 'react-router-dom'
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from '@mui/x-date-pickers'
import CustomUploadButton from '../../ui-components/formik/CustomUploadButton'
import startupInformationIcon from "../../assets/startup_information.png"
import referralDetailsIcon from "../../assets/referral_details.png"
import startupDetailsIcon from "../../assets/startup_details.png"
import personalInformationImage from '../../assets/personal_information.png'
import selectCityImage from '../../assets/select_city.png'

import AddIcon from "../../assets/add_button.png"
import EditIcon from "../../assets/edit.png"
import DeleteIcon from "../../assets/delete.png"
import preSeed from "../../assets/stage_icons/preSeed.png"
import seed from "../../assets/stage_icons/seed.png"
import seriesA from "../../assets/stage_icons/seriesA.png"
import seriesB from "../../assets/stage_icons/seriesB.png"
import seriesC from "../../assets/stage_icons/seriesC.png"
import seriesD from "../../assets/stage_icons/seriesD.png"
import IPO from "../../assets/stage_icons/ipo.png"
import { FieldArray, Form, FormikProvider, useFormik } from 'formik'
// import RedirectionPopup from './popups/login/RedirectionPopup'
import PerfectScrollbar from 'react-perfect-scrollbar'
import "react-perfect-scrollbar/dist/css/styles.css";
import CustomUrlField from '../../ui-components/CustomUrlField'
import CustomInputField from '../../ui-components/formik/CustomInputField'
import CustomBackgroundComponent from '../../ui-components/CustomBackgroundComponent'
import CustomLabel from '../../ui-components/CustomLabel'
import moment from 'moment'
import * as Yup from "yup"
import toast from 'react-hot-toast'
import { AddStartup, FetchAllSectors, FetchAllStages } from '../../api'
import Cookies from 'js-cookie'
import { useTheme } from '@emotion/react'
import CustomLogoButton from '../../ui-components/formik/CustomLogoButton'
import CustomRichTextEditor from '../../ui-components/formik/CustomRichTextEditor'
import CustomInfoIcon from '../../ui-components/CustomInfoIcon'
import CustomToast from '../../ui-components/CustomToast'
import placeHolderImage from "../../assets/user_profile.png"
import AuthWrapper1 from '../pages/AuthWrapper1'
import CustomHeaderIcon from '../../ui-components/CustomHeaderIcon'
import { containerRef, getFileIcon } from '../../utils'
import CustomValidationText from '../../ui-components/CustomValidationText'

const referral_types = [
    {
        value: "Friends",
        label: "Friends",
    },
    {
        value: "Incubator",
        label: "Incubator",
    },
    {
        value: "LinkedIn",
        label: "LinkedIn",
    },
    {
        value: "Google",
        label: "Google",
    },
    {
        value: "Other",
        label: "Other",
    },
];

const inputProps = {
    maxLength: 1, // Restrict input to a single character
    inputMode: 'numeric', // Use numeric keyboard on mobile devices
    pattern: "[0-9]*", // Only allow digits 0-9
    // title: "Please enter a number between 0 and 9",
    style: {
        textAlign: 'center', // Center the text in the input
    },
    min: 0,
    onKeyDown: (event) => {
        // Prevent the minus sign (-) from being entered
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Get the input value
        const inputValue = event.key;

        // Allow only numeric characters (0-9) and certain control keys
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];

        // Check if the pressed key is in the allowedKeys array
        if (!allowedKeys.includes(inputValue)) {
            // If not, prevent the key from being entered
            event.preventDefault();
        }
    },
    onInput: (event) => {
        // Get the input value
        const inputValue = event.target.value;

        // Remove non-numeric characters using a regular expression
        const numericValue = inputValue.replace(/[^0-9]/g, '');

        // Update the input field value with the filtered numeric value
        event.target.value = numericValue;
    },
    onKeyDown: (event) => {
        // Prevent the input of minus sign (key code 189)
        if (event.keyCode === 189 || event.key === "-") {
            event.preventDefault();
        }
    },
}

const StartupInformation = ({ progress, setProgress, step, formik, formationDate, setFormationDate, formationDateError, currentStep, setCurrentStep, isLoading, isUpdating }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isTablet = useMediaQuery(theme.breakpoints.up('sm'))

    // const [currentStep, setCurrentStep] = React.useState(step ? step : 1);
    // const { mutateAsync: AddStartupAsync, status: AddStartupStatus, error: AddStartupError, isLoading: isVerifying, data: AddStartupResponse } = AddStartup();
    const { data: startup_stages, isRefetching: isRefetchingStages, isLoading: isLoadingStages, refetch: refetchStages } = FetchAllStages()
    const { data: startup_sectors, isRefetching: isRefetchingSectors, isLoading: isLoadingSectors, refetch: refetchSectors } = FetchAllSectors()

    const [isPressed, setIsPressed] = useState(true)

    return (
        <>
            {/* <Modal open={isLoading == true || isLoadingStages}>
                <Wrapper style={{
                    display: 'grid', height: '100vh',
                    width: "100%",
                    placeItems: 'center'
                }}>
                    <CircularProgress sx={{ color: theme?.palette.customColors.b300 }} />
                </Wrapper>
            </Modal> */}
            <AuthWrapper1>
                <Grid xs={12} container display={"flex"} padding={{ xs: "1rem 1rem 1rem 1rem", sm: "2rem" }} sx={{
                    maxWidth: '1440px !important',
                    marginInline: 'auto',
                    height: '100%'
                }}>
                    <Grid item xs={12} sm={12} md={6.6} display={"grid"} sx={{ padding: { xs: "1rem 1rem 1rem 1rem", sm: "1rem 2rem", md: "2rem 2rem 1rem 2rem", lg: "2rem 4rem 2rem 5rem" }, height: '100%' }} gap={"1rem"}><FormikProvider value={formik}>
                        <Form onSubmit={(e) => e.preventDefault()} onKeyDown={(e) => {

                            // if (currentStep == 7 || currentStep == 8) {
                            //     if (e.key === 'Enter' && !e.shiftKey) {
                            //         formik.handleSubmit();
                            //     }
                            // }
                            // else {
                            //     return;
                            // }
                        }} style={{ maxHeight: "calc(100vh - 150px)", overflow: 'hidden' }}>
                            <Grid xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"column"} sx={{ maxHeight: "80vh", overflow: "hidden" }}>
                                <CustomHeaderIcon customIcon={currentStep >= 5 ? (currentStep === 10 ? startupInformationIcon : currentStep === 11 ? startupInformationIcon : currentStep === 12 ? referralDetailsIcon : startupDetailsIcon) : startupInformationIcon} />
                                <Typography variant='title' marginTop={1} textAlign={{ xs: "center", md: "start" }}>{currentStep >= 5 ? ( currentStep === 10 ? "Founder Information" : currentStep === 11 ? "Co-founder Information" : currentStep === 12 ? "Referral Details" : "Startup Details") : "Profile Picture"}</Typography>
                                {currentStep === 6 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1}>Provide stage information for your startup</Typography>}
                                {currentStep === 7 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1}>Provide the following information for your startup</Typography>}
                                {currentStep === 8 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1}>Provide the following information for your startup</Typography>}
                                {currentStep === 9 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1}>Share your online presence</Typography>}
                                {currentStep === 10 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1}>Provide details about founder(s)</Typography>}
                                {currentStep === 11 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1}>Provide details about co-founder(s)</Typography>}
                                {currentStep === 12 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1}>Tell us how did you hear about Backrr</Typography>}
                                
                                {/* {currentStep === 6 &&
                                    <>
                                      
                                        <CustomLabel sx={{ marginTop: "1.5rem" }}>WHAT IS THE STAGE OF YOUR STARTUP? {formik.errors.stage_id && (
                                            <CustomValidationText value={formik.errors.stage_id} />
                                        )}</CustomLabel>

                                       
                                    </>
                                } */}
                                <PerfectScrollbar containerRef={containerRef} style={{
                                    minHeight: "35vh", height: '100%',
                                    paddingRight: isDesktop ? "0.5rem" : 0,
                                    //  maxHeight: 'calc(100vh - 205px)',
                                    overflowX: 'hidden', marginTop: (currentStep === 5 || currentStep === 6 ? 0 : "1.5rem"), paddingBottom:  currentStep === 5 || currentStep === 6 ? 0 : '1.5rem'
                                }} className="scrollbar-container">
                                    
                                    
                                  
                                    {currentStep === 7 && <>
                                        <Grid container xs={12} gap={0} paddingBottom={4} paddingRight={1}>
                                            {/* <Grid xs={12} item container style={{ display: 'flex', alignItems: 'flex-start' }}> */}
                                            <Grid item xs={12} sm={12}>
                                                <CustomUploadButton name={"pitch_deck"} label={"UPLOAD PITCH DECK"} handleUpdateFile={(file) => { formik.setFieldValue("pitch_deck", file) }} selectedFile={formik.values.pitch_deck} accept='.pdf' typeSpecifyText='PDF Only (Max: 5 MB)' customUploadIcon={getFileIcon("pdf")} />
                                                {/* {formik.errors.pitch_deck && formik.touched.pitch_deck && (
                                                    <span style={{ color: 'red', lineHeight: '2rem', fontSize: "0.875rem" }}>
                                                        ({formik.errors.pitch_deck})
                                                    </span>
                                                )} */}
                                            </Grid>
                                            <Grid item xs={12} sm={12} mt={2} height={"min-content"}>
                                                <CustomRichTextEditor
                                                    name="description"
                                                    label={
                                                        <>
                                                            STARTUP OVERVIEW
                                                            {/* <img src="" alt="" /> */}
                                                        </>
                                                    }
                                                    placeholder={"Enter startup overview"}
                                                    value={formik.values?.description}
                                                    handleChange={(newValue) =>
                                                        formik.setFieldValue(
                                                            "description",
                                                            newValue
                                                        )
                                                    }
                                                    maxLength={2000}
                                                />
                                                {formik.errors.description && formik.touched.description && (
                                                    <CustomValidationText value={formik.errors.description} />
                                                )}
                                            </Grid>
                                            {/* </Grid> */}
                                        </Grid>
                                    </>}
                                    {currentStep === 8 && <>
                                        <Grid container xs={12} gap={0}>
                                            <Grid item xs={12} sm={12}>
                                                <CustomUploadButton name="elevator_pitch" label={
                                                    <Box display="flex" alignItems='center' gap='4px'>
                                                        HAVE ELEVATOR PITCH? 
                                                        <span style={{color: theme?.palette?.customColors?.g100}}>(Optional)</span>
                                                        {/* <CustomInfoIcon defaultTitle={"Elevator Pitch"} /> */}
                                                    </Box>} handleUpdateFile={(file) => { formik.setFieldValue("elevator_pitch", file) }} selectedFile={formik.values.elevator_pitch} accept='video/mp4,video/x-m4v,video/' typeSpecifyText='MP4, M4V (Max: 100 MB)' customUploadIcon={getFileIcon("mp4")} />
                                                {/* <LinearProgress value={progress} /> */}
                                                {/* <CustomInputField value={formik.values.elevator_pitch} name="elevator_pitch" onChange={(evt) => formik.setFieldValue("elevator_pitch", evt.target.value)} label={<>HAVE ANY ELEVATOR PITCH? <span>(OPTIONAL)</span></>} placeholder="Enter your elevator pitch" fullWidth /> */}
                                                <CustomInputField labelStyle={{ marginTop: '1rem' }} value={formik.values.problem_solving} name="problem_solving" onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (value.length <= 160) {
                                                        formik.setFieldValue("problem_solving", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))
                                                    }
                                                    else {
                                                        formik.setFieldValue("problem_solving", String(e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))?.substring(0, 160))
                                                    }
                                                }} label={"WHAT PROBLEM ARE YOU SOLVING?"} placeholder="Enter a description..." multiline minRows={4} maxRows={4} maxLength={160} fullWidth data-problem-solving={160 - formik.values?.problem_solving?.length + `${(160 - formik.values?.problem_solving?.length) > 1 ? " characters" : " character"} left`}
                                                    sx={{
                                                        "::after": {
                                                            content: 'attr(data-problem-solving)',
                                                            position: "absolute",
                                                            bottom: formik.errors.problem_solving ? 30 : 5,
                                                            right: 5,
                                                            background: 'white',
                                                            color: formik.values?.problem_solving?.length === 200 ? "red" : 'graytext',
                                                            padding: "0 5px",
                                                            fontSize: "0.75rem"
                                                        },
                                                    }} />
                                            </Grid>
                                        </Grid>
                                    </>}
                                    {currentStep === 9 && <>
                                        <Grid item container xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"row"}>
                                            <Grid item xs={12} display={"flex"} gap={0}>
                                                <CustomUrlField type="text" value={formik.values.website_url} name="website_url" label={"WEBSITE URL"} placeholder="www.backrr.com" onChange={(e) => formik.setFieldValue("website_url", String(e.target.value)?.startsWith("https://") ? String(e.target.value)?.replace("https://", "") : e.target.value)} />
                                            </Grid>
                                            <Grid xs={12} item container style={{ display: 'flex', alignItems: 'center' }}>
                                                <CustomUrlField label={<>STARTUP LINKEDIN PAGE <span style={{color: theme?.palette?.customColors?.g100}}>(Optional)</span> </>} name="linkedin_url" value={formik.values?.linkedin_url} onChange={(e) => formik.setFieldValue("linkedin_url", String(e.target.value)?.startsWith("https://") ? String(e.target.value)?.replace("https://", "") : e.target.value)} placeholder='www.linkedin.com' />
                                            </Grid>
                                        </Grid>
                                    </>}
                                    {currentStep === 10 && <>
                                        {/* {console.log(formik.values?.founder, "founder")} */}
                                        <Grid item container xs={12} display={"flex"} gap={"0"} justifyContent={"space-between"} flexDirection={"column"}>
                                            <Box>
                                                <Grid xs={12} mb={2}>
                                                    <CustomLogoButton label="PHOTO" handleUpdateFile={(file) => { formik.setFieldValue("founder.image", file) }} name={"founder.image"} value={formik.values?.founder?.image} Pwidth={90} Pheight={90} />
                                                </Grid>
                                                <Grid xs={12}>
                                                    <CustomInputField
                                                        label={"NAME"}
                                                        name={`founder.name`}
                                                        value={formik.values?.founder?.name}
                                                        placeholder="Enter founder name"
                                                        onChange={(e) => formik.setFieldValue(`founder.name`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))}
                                                    />
                                                </Grid>
                                                <Grid xs={12}>
                                                    <CustomInputField
                                                        label={"TITLE"}
                                                        name={`founder.title`}
                                                        value={formik.values?.founder?.title}
                                                        placeholder="Enter founder title"
                                                        onChange={(e) => formik.setFieldValue(`founder.title`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))}
                                                    />
                                                </Grid>
                                                <Grid xs={12}>
                                                    <CustomInputField
                                                        label={"BIO"}
                                                        name={`founder.bio`}
                                                        value={formik.values?.founder?.bio}
                                                        placeholder="Enter founder bio"
                                                        onChange={(e) => { e.target.value?.length < 160 && formik.setFieldValue(`founder.bio`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1)) }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} display={"flex"} paddingBottom={2}>
                                                    <CustomUrlField
                                                        label={"LINKEDIN PROFILE URL"}
                                                        placeholder="www.linkedin.com"
                                                        name={`founder.linkedin_url`}
                                                        value={formik.values?.founder?.linkedin_url}
                                                        onChange={(e) => formik.setFieldValue(`founder.linkedin_url`, String(e.target.value)?.startsWith("https://") ? String(e.target.value)?.replace("https://", "") : e.target.value)}
                                                    />
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </>}
                                    {currentStep === 11 && <>
                                        <Grid
                                            container
                                            xs={12}
                                            display="flex"
                                            gap={0}
                                            justifyContent="space-between"
                                            flexDirection="column"
                                            paddingBottom={2}
                                        >
                                            <FieldArray
                                                name="coFoundersArray"
                                                render={(arrayHelpers) => (
                                                    <>
                                                        {formik.values?.coFoundersArray?.length > 0 &&
                                                            formik.values?.coFoundersArray?.map((current, index) => (
                                                                <Box key={index}>
                                                                    {current?.edit ? (
                                                                        <>
                                                                            <Grid item xs={12} sx={{ marginBottom: 1, position: 'relative' }} pt={1}>
                                                                                <CustomLogoButton
                                                                                    label="PHOTO"
                                                                                    handleUpdateFile={(file) => {
                                                                                        formik.setFieldValue(`coFoundersArray.${index}.image`, file);
                                                                                    }}
                                                                                    name={`coFoundersArray.${index}.image`}
                                                                                    value={current?.image}
                                                                                    Pwidth={90} Pheight={90}
                                                                                />
                                                                                {/* {formik.values?.coFoundersArray?.length > 1 && ( */}
                                                                                <IconButton
                                                                                    sx={{ position: "absolute", top: "0rem", right: "1.2rem", padding: 0 }}
                                                                                    onClick={() => {
                                                                                        if (window.confirm("Are you sure you want to delete?") === true) {
                                                                                            arrayHelpers.remove(index);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <img src={DeleteIcon} rel="preload" width={30} height={30} />
                                                                                </IconButton>
                                                                                {/* )} */}
                                                                            </Grid>
                                                                            <Grid item xs={12} position="relative" pt={1}>
                                                                                <CustomInputField
                                                                                    label="NAME"
                                                                                    name={`coFoundersArray.${index}.name`}
                                                                                    value={current?.name}
                                                                                    placeholder="Enter co-founder name"
                                                                                    onChange={(e) =>
                                                                                        formik.setFieldValue(
                                                                                            `coFoundersArray.${index}.name`,
                                                                                            e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1)
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <CustomInputField
                                                                                    label="TITLE"
                                                                                    name={`coFoundersArray.${index}.title`}
                                                                                    value={current?.title}
                                                                                    placeholder="Enter co-founder title"
                                                                                    onChange={(e) =>
                                                                                        formik.setFieldValue(
                                                                                            `coFoundersArray.${index}.title`,
                                                                                            e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1)
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                            <Box>
                                                                                <Grid item xs={12} display="flex">
                                                                                    <CustomUrlField
                                                                                        label="LINKEDIN PROFILE URL"
                                                                                        placeholder="www.linkedin.com"
                                                                                        name={`coFoundersArray.${index}.linkedin_url`}
                                                                                        value={current?.linkedin_url}
                                                                                        onChange={(e) =>
                                                                                            formik.setFieldValue(
                                                                                                `coFoundersArray.${index}.linkedin_url`,
                                                                                                String(e.target.value)?.startsWith("https://")
                                                                                                    ? String(e.target.value)?.replace("https://", "")
                                                                                                    : e.target.value
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Grid>
                                                                            </Box>
                                                                        </>
                                                                    ) : (
                                                                        <Box
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            justifyContent="space-between"
                                                                            border="1px solid #A1A3A7"
                                                                            padding="7px 18px"
                                                                            borderRadius="4px"
                                                                        >
                                                                            <Box display="flex" alignItems="center" color={theme?.palette?.customColors?.g100} gap={1}>
                                                                                <span>{current?.name}</span>
                                                                                {current?.linkedin_url && <img src={linkedInIcon} rel="preload" width={24} height={24} />}
                                                                            </Box>
                                                                            <Box display="flex" gap={1}>
                                                                                <IconButton onClick={() => formik.setFieldValue(`coFoundersArray.${index}.edit`, true)}>
                                                                                    <img src={EditIcon} rel="preload" width={30} height={30} />
                                                                                </IconButton>
                                                                                <IconButton onClick={() => arrayHelpers.remove(index)}>
                                                                                    <img src={DeleteIcon} rel="preload" width={30} height={30} />
                                                                                </IconButton>
                                                                            </Box>
                                                                        </Box>
                                                                    )}
                                                                    {formik.values?.coFoundersArray?.length - 1 === index && (
                                                                        <>
                                                                            <Box
                                                                                sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    gap: 1,
                                                                                    justifyContent: "space-between",
                                                                                }}
                                                                            >
                                                                                <Box
                                                                                    sx={{ cursor: "pointer" }}
                                                                                    onClick={() => {
                                                                                        if (current?.name !== "" && current?.title !== "" && current?.image !== "" && current?.image !== null && current?.linkedin_url !== "") {
                                                                                            formik.setFieldValue(`coFoundersArray.${index}`, { ...current, edit: false }).then(() =>
                                                                                                arrayHelpers.insert(index + 1, {
                                                                                                    name: "",
                                                                                                    title: "",
                                                                                                    image: null,
                                                                                                    linkedin_url: "",
                                                                                                    edit: true,
                                                                                                })
                                                                                            );
                                                                                        } else {
                                                                                            formik.handleSubmit();
                                                                                            // toast.error(
                                                                                            //     <CustomToast
                                                                                            //         message="Write Founder's Name"
                                                                                            //         style={{ padding: "0px 15px" }}
                                                                                            //         closeIcon={true}
                                                                                            //         closeIconPosition="center"
                                                                                            //     />,
                                                                                            //     {
                                                                                            //         duration: 2000,
                                                                                            //     }
                                                                                            // );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <IconButton>
                                                                                        <Tooltip title="Add another co-founder">
                                                                                            <img src={AddIcon} rel="preload" width={32} height={32} />
                                                                                        </Tooltip>
                                                                                    </IconButton>
                                                                                    <Typography marginLeft="1rem" variant="caption" color="#000">
                                                                                        Add another co-founder
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Box>
                                                                            <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: "space-between" }}>
                                                                                <Typography
                                                                                    onClick={() => setCurrentStep(currentStep + 1)}
                                                                                    sx={{
                                                                                        cursor: "pointer",
                                                                                        opacity: isPressed ? 1 : 0.2,
                                                                                        marginLeft: "auto",
                                                                                        marginRight: "1rem",
                                                                                        marginBottom: "1rem",
                                                                                    }}
                                                                                    onMouseDown={() => setIsPressed(false)}
                                                                                    onMouseUp={() => setIsPressed(true)}
                                                                                    color={theme?.palette.customColors.b300}
                                                                                    fontSize="1rem"
                                                                                >
                                                                                    <span>Skip</span>
                                                                                </Typography>
                                                                            </Box>
                                                                        </>
                                                                    )}
                                                                    {formik.values?.coFoundersArray?.length > 1 && <Divider sx={{ marginTop: 2, marginBottom: 2 }} />}
                                                                </Box>
                                                            ))}

                                                        {formik.values?.coFoundersArray?.length === 0 && (
                                                            <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: "space-between" }}>
                                                                <Box sx={{ cursor: "pointer" }}>
                                                                    <IconButton
                                                                        onClick={() =>
                                                                            arrayHelpers.insert(0, {
                                                                                name: "",
                                                                                title: "",
                                                                                image: null,
                                                                                linkedin_url: "",
                                                                                edit: true,
                                                                            })
                                                                        }
                                                                    >
                                                                        <Tooltip title="Add user">
                                                                            <img rel="preload" src={AddIcon} width={32} height={32} />
                                                                        </Tooltip>
                                                                    </IconButton>
                                                                    <Typography
                                                                        onClick={() =>
                                                                            arrayHelpers.insert(0, {
                                                                                name: "",
                                                                                title: "",
                                                                                image: null,
                                                                                linkedin_url: "",
                                                                                edit: true,
                                                                            })
                                                                        }
                                                                        marginLeft="1rem"
                                                                        variant="caption"
                                                                        color="#000"
                                                                    >
                                                                        Add more Co-founder details
                                                                    </Typography>
                                                                </Box>
                                                                <Typography
                                                                    onClick={() => setCurrentStep(currentStep + 1)}
                                                                    sx={{ cursor: "pointer", opacity: isPressed ? 1 : 0.2 }}
                                                                    onMouseDown={() => setIsPressed(false)}
                                                                    onMouseUp={() => setIsPressed(true)}
                                                                    color={theme?.palette.customColors.b300}
                                                                    fontSize="1rem"
                                                                >
                                                                    <span>Skip</span>
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </Grid>

                                    </>}
                                    {currentStep === 12 && <>
                                        <Grid item container xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"row"}>
                                            <CustomLabel>SELECT REFERRAL MEDIUM  {formik.errors.referral_type && formik.touched.referral_type && (
                                                <CustomValidationText value={formik.errors.referral_type}/>
                                            )}</CustomLabel>
                                            <Grid
                                                display={"flex"}
                                                flexWrap={"wrap"}
                                                justifyContent={"center"}
                                                item
                                                container
                                                xs={12}
                                                gap={"1rem"}
                                            >
                                                {referral_types?.map((referral, index) => {
                                                    return (
                                                        <Grid
                                                            role={"button"}
                                                            item
                                                            xs={12}
                                                            sm={referral_types?.length % 2 != 0 && index === referral_types?.length - 1 ? 12 : 5.75}
                                                            md={referral_types?.length % 2 != 0 && index === referral_types?.length - 1 ? 12 : 5.65}
                                                            lg={referral_types?.length % 2 != 0 && index === referral_types?.length - 1 ? 12 : 5.8}
                                                            onClick={() => {
                                                                formik.setFieldValue(
                                                                    "referral_type",
                                                                    referral?.value
                                                                );
                                                            }}
                                                            sx={{
                                                                background:
                                                                    formik.values.referral_type ==
                                                                        referral?.value
                                                                        ? theme?.palette?.customColors?.y50
                                                                        : theme?.palette?.customColors?.whiteBG,
                                                                padding: "12px 16px",
                                                                height: 55,
                                                                borderRadius: "8px",
                                                                display: "grid",
                                                                placeItems: "center",
                                                                border: `1px solid ${formik.values.referral_type ==
                                                                    referral?.value
                                                                    ? theme?.palette?.customColors?.y300
                                                                    : theme?.palette?.customColors?.g75
                                                                    }`,
                                                                boxShadow: "0px 1px 2px 0px #1018280D",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                color={theme.palette.customColors.g200}
                                                                fontSize="0.875rem"
                                                            >
                                                                {referral?.value}
                                                            </Typography>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                            {formik.values.referral_type === "Other" && <Grid item xs={12} display={"flex"} gap={0} marginTop={2}>
                                                <CustomInputField value={formik.values.referral_name} name="referral_name" label={"ENTER REFERRER NAME"} placeholder="Enter referrer name" onChange={(e) => formik.setFieldValue("referral_name", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} />
                                            </Grid>}
                                        </Grid>
                                    </>}
                                </PerfectScrollbar>
                            </Grid>
                        </Form>
                    </FormikProvider>
                        <Grid item container xs={12} display={"flex"} alignItems={"flex-end"} justifyContent={"center"} gap={3}>
                            <Grid xs={5} md={5.5} lg={5.65} item>
                                <BLUEBORDER sx={{ textTransform: "none", padding: 2, borderWidth: 1, borderRadius: "8px", height: '56.5px' }} onClick={(e) => {
                                    e.preventDefault();
                                    setCurrentStep(currentStep - 1);
                                    window.scrollTo(0, 0);
                                }} fullWidth>Back</BLUEBORDER>
                            </Grid>
                            <Grid xs={5} md={5.5} lg={5.65} item>
                                <CustomButton sx={{ padding: 2, borderRadius: "8px", height: '56.5px' }} fullWidth onClick={() => {
                                    formik.handleSubmit()
                                }}>{isUpdating ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.4rem'} /> : currentStep === 12 ? "Submit" : "Next"}</CustomButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <CustomBackgroundComponent bgImage={bgImage} />
                </Grid>
            </AuthWrapper1 >
        </>
    )
}

export default StartupInformation