import React from 'react';
import CustomTable from '../../../ui-components/CustomTable';
import { B300, B400, WHITEBG } from '../../../ui-components/CustomButton';
import { Box, Button, IconButton, Menu, MenuItem, useMediaQuery } from '@mui/material';
import { ReactComponent as MoreOptionsIcon } from "../../../assets/rounds_icons/more_solid_icon.svg";
import { useTheme } from '@emotion/react';
import { ReactComponent as UploadOutlinedIcon } from "../../../assets/upload-cloud-icon-outlined.svg"

const RoundDocuments = ({ data }) => {

    const theme = useTheme();

    const isNotLargeScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [openInvitePopup, setOpenInvitePopup] = React.useState(false);

    const handleInvitePopupOpen = () => {
        setOpenInvitePopup(true);
    };

    const handleInvitePopupClose = () => {
        setOpenInvitePopup(false);
    };

    const handleInviteInvestor = () => {
        handleInvitePopupClose();
    };

    const columns = React.useMemo(
        () => [
            { Header: 'File Name', accessor: 'filename', minWidth: 200 },
            { Header: 'File Size', accessor: 'size', minWidth: 100 },
            { Header: 'Last updated', accessor: 'updated_date', minWidth: 100 },
            { Header: 'Uploaded by', accessor: 'uploaded_by', minWidth: 100 },
            { Header: '', accessor: 'actions', minWidth: 50 },
        ],
        []
    );

    // Popper more menu
    const [anchorEl, setAnchorEl] = React.useState(null);

    const Actions = ({ index = 0 }) => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const [openMenu, setOpenMenu] = React.useState(false);

        const handleClickMenu = (event) => {
            setAnchorEl(event.currentTarget);
            setOpenMenu(true);
        };

        const handleCloseMenu = () => {
            setAnchorEl(null);
            setOpenMenu(false);
        };

        return (
            <Box display={"flex"} gap={1} position={"relative"}>
                {isNotLargeScreen && data?.length > 0 ? (
                    <div style={{ position: 'relative' }}>
                        <IconButton
                            sx={{ padding: 0 }}
                            id={`more-details-button-${index}`}
                            size="small"
                            aria-controls={openMenu ? 'more-details-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openMenu ? 'true' : undefined}
                            onClick={(e) => handleClickMenu(e)}
                        >
                            <MoreOptionsIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            id={`more-details-menu-${index}`}
                            open={openMenu}
                            MenuListProps={{
                                'aria-labelledby': `more-details-button-${index}`,
                            }}
                            onClose={handleCloseMenu}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    padding: "12px",
                                    borderRadius: "8px",
                                    "& > ul.MuiList-root": { padding: "0 !important" },
                                    bgcolor: theme.palette.customColors.g400,
                                    color: theme.palette.customColors.whiteBG,
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 11,
                                        width: 10,
                                        height: 10,
                                        bgcolor: theme.palette.customColors.g900,
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            {data?.length > 0 && <MenuItem
                                sx={{
                                    borderBottom: `1px solid ${theme.palette.customColors.g700}`,
                                    padding: '0.625rem 1rem',
                                }}
                                onClick={handleCloseMenu}
                            >
                                Download All
                            </MenuItem>}
                            <MenuItem
                                sx={{
                                    borderBottom: `1px solid ${theme.palette.customColors.g700}`,
                                    padding: '0.625rem 1rem',
                                }}
                                onClick={handleCloseMenu}
                            >
                                Upload
                            </MenuItem>
                        </Menu>
                    </div>
                ) : (
                    <>
                        {data?.length > 0 && <WHITEBG
                            sx={{
                                minWidth: "0 !important",
                                border: `1px solid #D0D5DD !important`,
                                padding: "8px 14px"
                            }}
                        >
                            Download All
                        </WHITEBG>}
                        <B400
                            sx={{
                                background: theme.palette.customColors.indigo700,
                                padding: "0.625rem 1rem"
                            }}
                            startIcon={
                                <UploadOutlinedIcon style={{ width: 20, height: 20 }} />
                            }
                            onClick={handleInvitePopupOpen}
                        >
                            Upload
                        </B400>
                    </>
                )}
            </Box>
        );
    };


    const RowActions = () => {
        return (
            <Box display={"flex"} gap={1}>
                <Button sx={{ minWidth: "1rem" }}>
                    <MoreOptionsIcon />
                </Button>
            </Box>
        );
    };

    return (
        <>
            {/* <ModalComponent open={openInvitePopup} onClose={handleInvitePopupClose} style={{maxWidth: 640, width: "100%"}}>
                <InviteInvestorPopopup handleClose={handleInvitePopupClose} handleSubmit={handleInviteInvestor} />
            </ModalComponent> */}
            <CustomTable columns={columns} data={data} title={"Files Uploaded"} actions={Actions} rowActions={RowActions} />
        </>
    );
};

export default RoundDocuments;
