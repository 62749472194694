import { lazy } from 'react';
import RedirectionScreen from '../views/pages/RedirectionScreen';
import WelcomeScreen from '../views/dashboard/WelcomeScreen';
import MainRoutes from './MainRoutes';
import StartupOnboarding from '../views/startupOnboarding';
import StartupDetail from '../views/startupDetail'
import PitchDeck from '../views/PitchDeck';
import WhatsAppSuccessScreen from '../views/pages/WhatsAppSuccessScreen';

// // login option 3 routing
// const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
// const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const CreatProfileRoutes = {
    path: '',
    // element: <ProfileInformation />,
    children: [
        {
            path: "",
            element: <StartupOnboarding />
        },
        {
            path: "onboarding",
            element: <StartupOnboarding />
        },
        // {
        //     path: '/create_profile',
        //     element: <ProfileInformation />,
        // },
        // {
        //     path: '/startup_information',
        //     element: <StartupInformation />,
        // },
        {
            path: '/thank-you',
            element: <RedirectionScreen />,
        },
        {
            path: '/whatsApp-success',
            element: <WhatsAppSuccessScreen />,
        },
        {
            path: "*",
            element: <StartupOnboarding />
        },
        // { ...MainRoutes }
    ]
};

export default CreatProfileRoutes;
