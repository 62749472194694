import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { B300 } from '../../ui-components/CustomButton';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Add, Create } from '@mui/icons-material';

const NoRounds = () => {
    const navigate = useNavigate();
    const theme = useTheme()
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="calc(100% - 164px)"
            textAlign="center"
            padding={3}
        >
            {/* <img
                src="/path-to-your-image/no-data-illustration.png" // Replace with your image path
                // alt="No Data"
                style={{ width: '200px', height: 200, marginBottom: '20px', background: 'gray', borderRadius: 8 }} // Adjust the size as needed
            /> */}
            <Typography variant="title" gutterBottom>
                No Rounds Available
            </Typography>
            <Typography variant="body1" marginBottom={2} gutterBottom>
                It seems like there are no rounds available at the moment.
            </Typography>
            <B300 onClick={() => navigate("/rounds/create_round")} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 600 }}
            // startIcon={<Create fontSize='1.25rem' />}
            >
                Create a New Round
            </B300>
        </Box>
    );
};

export default NoRounds;
