import React from "react";
import Sidebar from "../../layout/Sidebar";
import { Button } from "@mui/material";
import { auth, signOut } from "../../firebase/firebaseConfig";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import BreadcrumbHeader from "../../layout/BreadcrumbsHeader";
import ThemeBGWrapper from "../../ui-components/ThemeBGWrapper";

const Dashboard = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    // const clearCookies = () => {
    //   const cookies = document.cookie.split(";");

    //   for (const cookie of cookies) {
    //     const eqPos = cookie.indexOf("=");
    //     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    //     document.cookie =
    //       name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    //   }
    // };
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        // clearCookies();
        console.log("User signed out and cookies cleared successfully.");
      })
      .catch((error) => {
        // An error happened.
        console.error("Error signing out: ", error);
      });
    Cookies.remove("jwt", { path: "/" });
    navigate("/")
  };

  return (
    <div>
      <ThemeBGWrapper>
        <BreadcrumbHeader title={"Dashboard"} />
      </ThemeBGWrapper>
    </div>
  );
};

export default Dashboard;
