import Dashboard from "../views/dashboard";
import MainLayout from "../layout"
import Rounds from "../views/rounds/Rounds";
import CreateRound from "../views/rounds/CreateRound";
import CustomControlsView from "../views/controls/CustomControlsView";
import Error404 from "../ui-components/Error404";
import MyForm from "../views/controls/MyForm";
import ViewRound from "../views/rounds/ViewRound";
import WelcomeScreen from "../views/dashboard/WelcomeScreen";
import { Grid } from "@mui/material";
import ThemeBGWrapper from "../ui-components/ThemeBGWrapper";
import Reports from "../views/reports/Reports";
import CreateReport from "../views/reports/CreateReport";
import StartupDetail from '../views/startupDetail'
import Profile from "../views/Profile/Profile";

const MainRoutes = {
    path: '',
    // element: <> />,
    children: [
        {
            path: '',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <Dashboard />
                },
                {
                    path: 'rounds',
                    children: [
                        {
                            path: '',
                            element: <Rounds />,
                        },
                        {
                            path: 'create_round',
                            element: <CreateRound />,
                            children: [
                                {
                                    path: '',
                                },
                                {
                                    path: ':id',
                                    // element: <CreateRound />
                                },
                            ]
                        },
                        {
                            path: 'edit_round/:id',
                            element: <CreateRound />
                        },
                        {
                            path: 'view/:id',
                            element: <ViewRound />
                        },
                    ]
                },
                {
                    path: 'profile',
                    children: [
                        {
                            path: '',
                            element: <Profile />,
                        },
                        // {
                        //     path: 'edit_profile/:id',
                        //     element: <CreateRound />
                        // },
                        // {
                        //     path: 'view/:id',
                        //     element: <ViewRound />
                        // },
                    ]
                },
                {
                    path: 'reports',
                    children: [
                        {
                            path: '',
                            element: <Reports />
                        },
                        {
                            path: 'create_report',
                            element: <CreateReport />,
                            children: [
                                {
                                    path: '',
                                },
                                {
                                    path: ':id',
                                    // element: <CreateReport />
                                },
                            ]
                        },
                        {
                            path: 'edit_report/:id',
                            element: <CreateReport />
                        }
                        // ,
                        // {
                        //     path: 'view/:id',
                        //     element: <ViewRound />
                        // },
                    ],
                },
                // {
                //     path: "/customs",
                //     element: <CustomControlsView />
                // },
                // {
                //     path: "/form",
                //     element: <MyForm />
                // },
                {
                    path: 'reports',
                    children: [
                        {
                            path: '',
                            element: <Reports />
                        },
                        {
                            path: 'create_report',
                            element: <CreateReport />,
                            children: [
                                {
                                    path: '',
                                },
                                {
                                    path: ':id',
                                    // element: <CreateReport />
                                },
                            ]
                        },
                        {
                            path: 'edit_report/:id',
                            element: <CreateReport />
                        }
                        // ,
                        // {
                        //     path: 'view/:id',
                        //     element: <ViewRound />
                        // },
                    ],
                },
                {
                    path: '*',
                    element: <Error404 />
                },
            ]
        },
        {
            path: 's',
            element: <StartupDetail />
        },
        {
            path: '/welcome',
            element: <WelcomeScreen />,
        },
    ]
};

export default MainRoutes;
