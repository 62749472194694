import { useTheme } from '@emotion/react';
import { Box, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";

const CustomRichTextEditor = ({
  label,
  name,
  placeholder,
  value,
  handleChange,
  labelStyle = {},
  maxLength = 500,
  ...props
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const quillRef = useRef(null);

  // const getPlainText = (html) => {
  //   const tmp = document.createElement('div');
  //   tmp.innerHTML = html;
  //   return tmp.textContent || tmp.innerText || "";
  // };

  const getPlainText = (html) => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
  
    // Trim any excess whitespace or new lines
    let text = tmp.textContent || tmp.innerText || "";
  
    // Count new lines properly by replacing them with spaces to avoid blocking
    return text.replace(/\n/g, " ").trim();
  };
  
  const handleTextChange = (newValue, delta, source, editor) => {
    const plainText = editor.getText(); // Get plain text without HTML
    const newCharacterCount = plainText.trim()?.length;
  
    if (newCharacterCount <= maxLength) {
      setCharacterCount(maxLength - newCharacterCount);
      handleChange(newValue);
    } else {
      // Prevent exceeding the character limit by reverting to the previous value
      handleChange(value);
    }
  };
  
  const handleKeyDown = (e) => {
    // Allow arrow keys and navigation keys to work normally
    if (
      ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Home', 'End'].includes(e.key)
    ) {
      return;
    }
  
    if (e.key === 'Backspace' || e.key === 'Delete') {
      return; // Allow Backspace and Delete
    }
  
    // Check if user presses Enter
    if (e.key === 'Enter') {
      const plainText = getPlainText(value || "");
      const newCharacterCount = plainText.length;
  
      // Reduce character count when Enter is pressed
      if (newCharacterCount + 1 <= maxLength) {
        // Only allow Enter if character count is still within the limit
        setCharacterCount((prevCount) => prevCount - 1);
        return; // Allow Enter
      } else {
        e.preventDefault(); // Prevent Enter if at character limit
      }
    }
  
    // Prevent typing if at character limit
    if (characterCount <= 0 && !e.ctrlKey) {
      e.preventDefault(); // Prevent input if at character limit
    }
  
    // Other key combinations (e.g., Ctrl+A, Ctrl+V)
    if (e.ctrlKey && (e.key === 'a' || e.key === 'A')) {
      return; // Allow Ctrl + A
    }
  
    if (e.ctrlKey && (e.key === 'v' || e.key === 'V')) {
      const clipboardData = e?.clipboardData || window?.clipboardData;
      const pastedData = clipboardData?.getData('Text');
      const plainText = getPlainText(value || "");
      const remaining = maxLength - plainText?.length;
  
      if (remaining <= 0) {
        e.preventDefault();
        return;
      }
  
      if (pastedData?.length > remaining) {
        e.preventDefault();
        const truncatedData = pastedData?.slice(0, remaining);
        handleChange(truncatedData);
      }
    }
  };
  
  

  const [characterCount, setCharacterCount] = useState(
    maxLength - getPlainText(value || "")?.length
  );

  // Update character count whenever the value changes externally
  useEffect(() => {
    setCharacterCount(maxLength - getPlainText(value || "")?.length);
  }, [value, maxLength]);

  // const handleTextChange = (newValue, delta, source, editor) => {
  //   const plainText = editor.getText(); // Gets plain text without HTML
  //   const newCharacterCount = plainText.trim()?.length;

  //   if (newCharacterCount <= maxLength) {
  //     setCharacterCount(maxLength - newCharacterCount);
  //     handleChange(newValue);
  //   } else {
  //     // Prevent exceeding the character limit by reverting to the previous value
  //     handleChange(value);
  //   }
  // };

  // const handleKeyDown = (e) => {
  //   // Allow arrow keys and other navigation keys to work normally
  //   if (
  //     ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Home', 'End'].includes(e.key)
  //   ) {
  //     return;
  //   }
  
  //   if (e.key === 'Backspace' || e.key === 'Delete') {
  //     return; // Allow Backspace and Delete
  //   }

  //   if(e.key !== 'Enter'){
  //     e.preventDefault();
  //   }
  
  //   // If character count is zero and the key is not Ctrl, Enter, or Shift, prevent further input
  //   if (characterCount <= 0 && !e.ctrlKey && e.key !== 'Enter' && e.key !== 'Shift') {
  //     e.preventDefault();
  //   }
  
  //   if (e.ctrlKey && (e.key === 'a' || e.key === 'A')) {
  //     return; // Allow Ctrl + A
  //   }
  
  //   if (e.ctrlKey && (e.key === 'v' || e.key === 'V')) {
  //     // Handle paste via keyboard shortcut
  //     const clipboardData = e?.clipboardData || window?.clipboardData;
  //     const pastedData = clipboardData?.getData('Text');
  //     const plainText = getPlainText(value || "");
  //     const remaining = maxLength - plainText?.length;
  
  //     if (remaining <= 0) {
  //       e.preventDefault();
  //       return;
  //     }
  
  //     if (pastedData?.length > remaining) {
  //       e.preventDefault();
  //       const truncatedData = pastedData?.slice(0, remaining);
  //       handleChange(truncatedData);
  //     }
  //   }
  
  //   // Block the default Enter behavior unless Shift is pressed (if applicable)
  //   if (!e.shiftKey && e.key === 'Enter') {
  //     e.preventDefault();
  //   }
  // };
  

  // const handlePaste = (e) => {
  //   e.preventDefault();
  //   const clipboardData = e.clipboardData || window.clipboardData;
  //   let pastedData = clipboardData.getData('Text');

  //   // Function to get the plain text without HTML tags
  //   const getPlainText = (html) => {
  //     const tempDiv = document.createElement("div");
  //     tempDiv.innerHTML = html;
  //     return tempDiv.textContent || tempDiv.innerText || "";
  //   };

  //   const plainText = getPlainText(value || "");
  //   const remaining = maxLength - plainText?.length;

  //   if (remaining <= 0) {
  //     return; // Do nothing if there is no remaining character space
  //   }

  //   // Trim pasted data if it exceeds the remaining character limit
  //   if (pastedData?.length > remaining) {
  //     pastedData = pastedData.slice(0, remaining);
  //   }

  //   // Insert the trimmed text at the current cursor position
  //   const editor = quillRef.current.getEditor();
  //   const range = editor.getSelection();
  //   if (range) {
  //     editor.insertText(range.index, pastedData);
  //     handleChange(editor.root.innerHTML); // Update the value with the new content
  //   }
  // };


  // useEffect(() => {
  //   const editor = quillRef.current.getEditor();
  //   const editorRoot = editor.root;
  //   editorRoot.addEventListener('paste', handlePaste);

  //   return () => {
  //     editorRoot.removeEventListener('paste', handlePaste);
  //   };
  // }, [value, characterCount]);

  useEffect(() => {
    const editorRoot = quillRef.current?.getEditor()?.root;
    const handleDrop = (e) => {
      if (e.dataTransfer && e.dataTransfer.files.length > 0) {
        // Prevent drag and drop if the file is an image
        const file = e.dataTransfer.files[0];
        if (file.type.startsWith("image/")) {
          e.preventDefault();
        }
      }
    };

    editorRoot.addEventListener('drop', handleDrop);

    return () => {
      editorRoot.removeEventListener('drop', handleDrop);
    };
  }, []);

  const editorHeight = isSmallScreen ? '200px' : isMediumScreen ? '200px' : '150px';

  return (
    <div style={{ position: 'relative' }}>
      {label && (
        <Box
          component="label"
          sx={{
            color: theme.palette.customColors.g500,
            marginBottom: '8px',
            display: 'block',
            fontSize: "0.875rem",
            ...labelStyle,
          }}
        >
          {label}
        </Box>
      )}

      <div style={{ position: 'relative' }}>
        <ReactQuill
          ref={quillRef}
          style={{
            height: editorHeight,
            background: theme.palette.customColors.whiteBG,
            overflowY: 'auto',
            maxWidth: '100%',
          }}
          modules={{
            toolbar: {
              container: [
                [{ header: "1" }, { header: "2" }],
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ indent: "-1" }, { indent: "+1" }],
              ],
            },
            // Disable the link tool to prevent adding URLs that may exceed character count
            clipboard: {
              matchVisual: false,
            },
          }}
          formats={[
            "header",
            "bold",
            "italic",
            "underline",
            "list",
            "bullet",
            "indent",
            "align",
          ]}
          placeholder={placeholder}
          onChange={handleTextChange}
          value={value}
          onKeyDown={handleKeyDown}
          {...props}
        />
      </div>

      <div
        className="character_count"
        style={{
          right: '1rem',
          position: 'absolute',
          bottom: '5px',
          color: characterCount === 0 ? 'GrayText' : 'GrayText',
          background: 'white',
          padding: '2px 5px',
          borderRadius: '4px',
          fontSize: '0.75rem',
        }}
      >
        {characterCount} {characterCount > 1 ? "characters" : "character"} left
      </div>
    </div>
  );
};

export default CustomRichTextEditor;
