import { Box, Card, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as EditIcon } from "../../assets/rounds_icons/edit_icon.svg";
import PieChart from '../../ui-components/PieChart'
import { useTheme } from '@emotion/react';
import { handleDateValues } from '../../utils';
import { WHITEBG } from '../../ui-components/CustomButton';
import moment from 'moment';

const CardHeader = ({ title, action = () => { } }) => {
    return <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mb={"0.75rem"}>
        <Typography fontWeight={700} fontSize={"1.25rem"}>
            {title}
        </Typography>
        <WHITEBG
            component="label"
            role={undefined}
            tabIndex={-1}
            sx={{
                minWidth: "0 !important",
                border: `1px solid #D0D5DD !important`,
                display: "flex",
                gap: '0.5rem',
                paddingInline: '0.5rem 0.875rem'
            }}
            onClick={action}
        >
            <EditIcon
                style={{
                    width: 20,
                    height: 20,
                }} />
            <Typography>
                Edit
            </Typography>
        </WHITEBG>
    </Box>
}

const RichTextDisplay = ({ htmlContent, color = "" }) => {
    return (
        <>
            {htmlContent && htmlContent?.length > 0 ?
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} style={{ color: color }} />
                : "-"
            }
        </>
    );
};

const PersonalDetails = ({ data: personalDetails, avatarList: avatarList }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
    console.log("personalDetails", personalDetails)
    let profileImage = null
    if (personalDetails?.custom_image_url?.trim()?.length > 0) {
        profileImage = personalDetails?.custom_image_url
    } else {
        if (personalDetails?.avatar_selected) {
            let selected = avatarList?.find(item => item._id == personalDetails?.avatar_selected)
            profileImage = selected?.image_url
        }
    }
    return (
        <Grid item container xs={12} gap={"1.25rem"} display={"grid"} sx={{ paddingBottom: '2rem' }} gridTemplateAreas={isLargeScreen ? `"first aside" "second aside" "third aside" "forth aside" "fifth aside" "sixth aside"` : `"first" "aside" "second" "third" "forth" "fifth" "sixth"`}
            gridTemplateColumns={isLargeScreen ? "2.5fr 1.5fr" : '1fr'} justifyContent={"space-between"}>
            <Grid sx={{ gridArea: 'first', maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                    <CardHeader title={"Personal Info"}
                    // action={() => { navigate(`/rounds/edit_round/${round_id}?page=0`) }} 
                    />
                    <Grid display={'flex'} alignItems = {'center'}>
                        {/* <Card sx={{ boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`,marginRight: '0.5rem' }}> */}
                            <img src={profileImage} style={{ width: isLargeScreen ? '20%' : '30%', objectFit: 'contain', marginRight: '1rem' }} />
                        {/* </Card> */}
                        <Grid
                            container
                            xs={12}
                            sx={{
                                // border: `1px solid ${theme?.palette?.customColors?.b75}`,
                                // borderRadius: "8px",
                                overflow: "hidden",
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                display={"flex"}
                                justifyContent={"space-between"}
                                sx={{
                                    padding: "5px",
                                    // backgroundColor:
                                    //     theme?.palette?.customColors?.b50,
                                }}
                            >
                                {/* <Typography>Name</Typography> */}
                                <Typography>
                                    {personalDetails?.title ? personalDetails?.title : ""} {personalDetails?.first_name + " " + personalDetails?.last_name}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display={"flex"}
                                justifyContent={"space-between"}
                                sx={{
                                    padding: "5px",
                                }}
                            >
                                {/* <Typography>Date of Birth</Typography> */}
                                <Typography>
                                    {personalDetails?.dd}-{personalDetails?.mm}-{personalDetails?.yyyy}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display={"flex"}
                                justifyContent={"space-between"}
                                sx={{
                                    padding: "5px",
                                    // backgroundColor:
                                    //     theme?.palette?.customColors?.b50,
                                }}
                            >
                                {/* <Typography>Location</Typography> */}
                                <Typography>
                                    {personalDetails?.city_id}{personalDetails?.city && personalDetails?.state && ", "}{personalDetails?.state}{(personalDetails?.state && personalDetails?.country) || (personalDetails?.city && personalDetails?.country) && ", "}{personalDetails?.country}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            {/* <Grid sx={{ gridArea: 'aside', maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                    <CardHeader title={"Profile Picture"}
                    // action={() => { navigate(`/rounds/edit_round/${round_id}?page=3`) }}
                    />
                    <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.g50}`, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                        <img src={profileImage} style={{ height: '10rem', width: '10rem', objectFit: 'contain' }} />
                    </Card>
                </Card>
            </Grid> */}
            <Grid sx={{ gridArea: 'second', maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>

                    <CardHeader title={"Investment Info"}
                    // action={() => { navigate(`/rounds/edit_round/${round_id}?page=1`) }} 
                    />
                    <Grid
                        container
                        xs={12}
                        sx={{
                            border: `1px solid ${theme?.palette?.customColors?.b75}`,
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Investment Amount Range</Typography>
                            <Typography>
                                {personalDetails?.investment_size}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                            }}
                        >
                            <Typography>No. of Startups Invested in</Typography>
                            <Typography>
                                {personalDetails?.investment_count}
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid sx={{ gridArea: 'third', maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                    <CardHeader title={"Invested Startups"}
                    // action={() => { navigate(`/rounds/edit_round/${round_id}?page=2`) }} 
                    />
                    <Grid
                        container
                        xs={12}
                        sx={{
                            border: `1px solid ${theme?.palette?.customColors?.b75}`,
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow sx={{
                                    padding: "12px 16px",
                                    backgroundColor:
                                        theme?.palette?.customColors?.g200
                                }}>
                                    <TableCell>
                                        <Typography textAlign={'left'} fontWeight={400} color={theme?.palette?.customColors?.g50}>Startup Name</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography textAlign={'right'} fontWeight={400} color={theme?.palette?.customColors?.g50}>
                                            Startup URL
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {personalDetails?.startups?.length > 0 && personalDetails?.startups?.map((startup, index) => {
                                    return <TableRow
                                        item
                                        key={index}
                                        xs={12}
                                        display={"flex"}
                                        justifyContent={"space-between"}
                                        sx={{
                                            padding: "12px 16px",
                                            backgroundColor:
                                                index % 2 === 0 ? theme?.palette?.customColors?.b50 : '',
                                        }}
                                    >
                                        <TableCell>
                                            <Typography textAlign={'left'} fontWeight={500} color={theme?.palette?.customColors?.g300}>
                                                {startup?.startup_name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography textAlign={'right'} fontWeight={400} color={theme?.palette?.customColors?.g200}>
                                                {startup?.startup_url}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                })}

                                {personalDetails?.startups?.length == 0 && <TableRow
                                    item
                                    xs={12}
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    sx={{
                                        padding: "12px 16px",
                                        backgroundColor:
                                            theme?.palette?.customColors?.b50,
                                    }}
                                >
                                    <TableCell colSpan={2} marginInline={"auto"} sx={{ textAlign: 'center' }}>No Invested Startups</TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                    </Grid>
                </Card>
            </Grid>
            <Grid sx={{ gridArea: 'forth', maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                <Card sx={{ padding: '1rem', boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>
                    <CardHeader title={"Social Info"}
                    // action={() => { navigate(`/rounds/edit_round/${round_id}?page=0`) }} 
                    />
                    <Grid
                        container
                        xs={12}
                        sx={{
                            border: `1px solid ${theme?.palette?.customColors?.b75}`,
                            borderRadius: "8px",
                            overflow: "hidden",
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>WhatsApp Notifications</Typography>
                            <Typography>
                                {personalDetails?.enabled ? "Enable" : "Disable"}
                            </Typography>
                        </Grid>
                        {personalDetails?.enabled && <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                            }}
                        >
                            <Typography>WhatsApp Number</Typography>
                            <Typography>
                                {personalDetails?.whatsapp_number}
                            </Typography>
                        </Grid>}
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    personalDetails?.enabled ? theme?.palette?.customColors?.b50 : "",
                            }}
                        >
                            <Typography>LinkedIn URL</Typography>
                            <Typography>
                                {personalDetails?.profile_url}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"space-between"}
                            sx={{
                                padding: "12px 16px",
                                backgroundColor:
                                    personalDetails?.enabled ? "" : theme?.palette?.customColors?.b50,
                            }}
                        >
                            <Typography>Publicly Discoverable</Typography>
                            <Typography>
                                {personalDetails?.linkedin_public_profile ? "Enabled" : "Disable"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    )
}

export default PersonalDetails