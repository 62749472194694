import { Box, CircularProgress, Divider, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, Modal, Paper, Select, Stack, Switch, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import personalInformationImage from '../../assets/personal_information.png'
import selectCountryImage from '../../assets/select_country.png'
import AddIcon from "../../assets/add_button.png"
import whatsapp_icon from "../../assets/whatsapp_main_icon.png"

import selectCityImage from '../../assets/select_city.png'
import investmentSize from '../../assets/investment_size.png'
import startupDetailsIcon from "../../assets/startup_details.png"
import linkedinAddIcon from "../../assets/linkedinhead_icon.png"
import startupInformationIcon from "../../assets/startup_information.png"
import startupInvestment from "../../assets/startup_investment.png"
import whatsappNotification from "../../assets/whatsapp_notification.png"
import eye_icon from "../../assets/eye.png"
import reserch from "../../assets/research.png"
import { BLUEBORDER, B300 as CustomButton } from '../../ui-components/CustomButton'
import CustomField from '../../ui-components/CustomField'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import "react-perfect-scrollbar/dist/css/styles.css";
import { ReactComponent as gpsIcon } from "../../assets/gps.svg"
import CustomInputField from '../../ui-components/formik/CustomInputField'
import CustomInputFieldOTP from '../../ui-components/CustomInputField'
import CustomUrlField from '../../ui-components/CustomUrlField'
import CustomLabel from '../../ui-components/CustomLabel'
import CustomBackgroundComponent from '../../ui-components/CustomBackgroundComponent'
import { FieldArray, Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup';
import CustomAutoComplete from '../../ui-components/CustomAutoComplete'
import { FetchAllAvatars, FetchAllCities, FetchAllCountries, FetchAllCountriesWithPhoneCodes, FetchAllInvestmentSize, FetchAllPopularCountries, FetchAllSectors, FetchAllStages, FetchAllStates, VerifyWhatsAppOTP } from '../../api'
import CustomPhone from '../../ui-components/CustomPhone'
import CustomLogoButton from '../../ui-components/formik/CustomLogoButton'
import placeHolderImage from "../../assets/user_profile.png"

import Cookies from "js-cookie"
import { LoaderIcon } from 'react-hot-toast'
import { useTheme } from '@emotion/react'
import AuthWrapper1 from '../pages/AuthWrapper1'
import CustomHeaderIcon from '../../ui-components/CustomHeaderIcon'
import { containerRef } from '../../utils'
import CustomValidationText from '../../ui-components/CustomValidationText'
import { Opacity, Refresh, RemoveRedEye } from '@mui/icons-material'
import SelectField from '../../ui-components/CustomDropdown'
import { useConfig } from '../../dynamicConfiguration/configContext'


// import RedirectionPopup from './popups/login/RedirectionPopup'
// import ModalComponent from '../../ui-components/ModalComponent'
const title = [
    {
        value: "Mr.",
        label: "Mr.",
    },
    {
        value: "Mrs.",
        label: "Mrs.",
    },
    {
        value: "Ms.",
        label: "Ms.",
    },
    {
        value: "Dr.",
        label: "Dr.",
    },
    {
        value: "Prof.",
        label: "Prof.",
    },
    {
        value: "CA",
        label: "CA",
    },
];

const OtpInputProps = {
    maxLength: 1, // Restrict input to a single character
    inputMode: 'numeric', // Use numeric keyboard on mobile devices
    pattern: "[0-9]*", // Only allow digits 0-9
    // title: "Please enter a number between 0 and 9",
    style: {
        textAlign: 'center', // Center the text in the input
    },
    min: 0,
    onKeyDown: (event) => {
        // Prevent the minus sign (-) from being entered
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Get the input value
        const inputValue = event.key;

        // Allow only numeric characters (0-9) and certain control keys
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];

        // Check if the pressed key is in the allowedKeys array
        if (!allowedKeys.includes(inputValue)) {
            // If not, prevent the key from being entered
            event.preventDefault();
        }
    },
    onInput: (event) => {
        // Get the input value
        const inputValue = event.target.value;

        // Remove non-numeric characters using a regular expression
        const numericValue = inputValue.replace(/[^0-9]/g, '');

        // Update the input field value with the filtered numeric value
        event.target.value = numericValue;
    },
    onKeyDown: (event) => {
        // Prevent the input of minus sign (key code 189)
        if (event.keyCode === 189 || event.key === "-") {
            event.preventDefault();
        }
    },
}

const numberInputProps = {
    inputMode: 'numeric', // Use numeric keyboard on mobile devices
    pattern: "[0-9]*", // Only allow digits 0-9
    // title: "Please enter a number between 0 and 9",
    style: {
        textAlign: 'center', // Center the text in the input
    },
    min: 0,
    onKeyDown: (event) => {
        // Prevent the minus sign (-) from being entered
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Get the input value
        const inputValue = event.key;

        // Allow only numeric characters (0-9) and certain control keys
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];

        // Check if the pressed key is in the allowedKeys array
        if (!allowedKeys.includes(inputValue)) {
            // If not, prevent the key from being entered
            event.preventDefault();
        }
    },
    onInput: (event) => {
        // Get the input value
        const inputValue = event.target.value;

        // Remove non-numeric characters using a regular expression
        const numericValue = inputValue.replace(/[^0-9]/g, '');

        // Update the input field value with the filtered numeric value
        event.target.value = numericValue;
    },
    onKeyDown: (event) => {
        // Prevent the input of minus sign (key code 189)
        if (event.keyCode === 189 || event.key === "-") {
            event.preventDefault();
        }
    },
    disableUnderline: true
}

const countryCodes = [
    { code: '+1', label: 'US' },
    { code: '+91', label: 'IN' },
]

const inputProps = {
    maxLength: 1,
    inputMode: 'numeric',
    pattern: "[0-9]*",
    style: {
        textAlign: 'center',
    },
    onKeyDown: (event) => {
        if (event.key === '-' || event.keyCode === 189) {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
        if (!allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
    },
    onInput: (event) => {
        const inputValue = event.target.value.replace(/[^0-9]/g, '');
        event.target.value = inputValue;
    }
};

const ProfileInformation = ({ progress, setProgress, step, formik, currentStep, setCurrentStep, isLoading, isUpdating, resend, refetchProfileDetails }) => {
    const { config } = useConfig()
    const theme = useTheme();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isTablet = useMediaQuery(theme.breakpoints.up('sm'))
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = React.useState("")
    const [searchState, setSearchState] = React.useState("")
    const [searchCountry, setSearchCountry] = React.useState("")
    const { data: countries, isRefetching: isRefetchingCountries, isLoading: isLoadingCountries, refetch: refetchCountries } = FetchAllCountries({ search: searchCountry })
    const { data: countriesWithPhoneCodes, isRefetching: isRefetchingCountriesPC, isLoading: isLoadingCountriesPC, refetch: refetchCountriesPC } = FetchAllCountriesWithPhoneCodes({})
    const { data: popular_countries, isRefetching: isRefetchingPopularCountries, isLoading: isLoadingPopularCountries, refetch: refetchPopularCountries } = FetchAllPopularCountries()
    const [currentCountry, setCurrentCountry] = React.useState(countries?.find(item => item?._id == formik?.values?.country_id))
    const { data: states, isRefetching: isRefetchingStates, isLoading: isLoadingStates, refetch: refetchStates } = FetchAllStates({ search: searchState, countryID: currentCountry !== null ? countries?.find(item => item?._id == formik.values?.country_id)?.value : currentCountry?.value })
    const [currentState, setCurrentState] = React.useState(states?.find(item => item?.value === formik?.values?.state_id)?.label || '')
    const { data: cities, isRefetching: isRefetchingCities, isLoading: isLoadingCities, refetch: refetchCities } = FetchAllCities({ search, stateID: currentState !== null ? states?.find(item => item?._id == formik.values?.state_id)?.value : currentState?.value, isPopular: 0 });
    const [currentCity, setCurrentCity] = React.useState(cities?.find(item => item?.value === formik?.values?.city_id)?.label || '');
    const { data: startup_stages, isRefetching: isRefetchingStages, isLoading: isLoadingStages, refetch: refetchStages } = FetchAllStages()
    const { data: startup_sectors, isRefetching: isRefetchingSectors, isLoading: isLoadingSectors, refetch: refetchSectors } = FetchAllSectors()
    const { data: avatarList, isRefetching: isRefetchingAvatarList, isLoading: isLoadingAvatarList, refetch: refetchAvatarList } = FetchAllAvatars()
    const { data: investmentSizeList, isRefetching: isRefetchingInvestmentSizeList, isLoading: isLoadingInvestmentSizeList, refetch: refetchInvestmentSizeList } = FetchAllInvestmentSize()
    const { mutateAsync: verifyWhatsAppOTPAsync, isLoading: isOTPLoading, data: verifyWhatsAppOTPResponse } = VerifyWhatsAppOTP();
    const [currency, setCurrency] = useState(null);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [selectedRange, setSelectedRange] = useState(null); // Track selected range
    const [allAmountRanges, setAllAmountRanges] = useState([])
    const [amountRanges, setAmountRanges] = useState([])
    const [selectedStages, setSelectedStages] = useState([])

    const [error, setError] = useState(null)
    const [countDown, setCountDown] = useState(30);
    const [otpValues, setOtpValues] = useState(Array(4).fill(''));

    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    useEffect(() => {
        if (countDown > 0) {
            const timer = setTimeout(() => setCountDown(prev => prev - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [countDown]);

    const number_investment = [
        {
            value: "01",
            label: "01",
        },
        {
            value: "02",
            label: "02",
        },
        {
            value: "03",
            label: "03",
        },
        {
            value: "04",
            label: "04",
        },
        {
            value: "05",
            label: "05",
        },
        {
            value: "06",
            label: "06",
        },
        {
            value: "07",
            label: "07",
        },
        {
            value: "08",
            label: "08",
        },
        {
            value: "09",
            label: "09",
        },
        {
            value: "10",
            label: "10",
        },
        {
            value: "11",
            label: "Invested in more than 10 startups",
        },
        {
            value: "12",
            label: config?.dynamicText?.newToInvestment,
        },

    ];

    useEffect(() => {
        console.log("investmentSizeList", investmentSizeList)
        setCurrencyOptions(investmentSizeList?.currencies)
        setAllAmountRanges(investmentSizeList?.amount)
    }, [investmentSizeList])

    useEffect(() => {
        if (currentStep == 6) {
            if (formik?.values?.investment_amount_type) {
                setCurrency(formik?.values?.investment_amount_type)
                formik.setFieldValue("investment_amount_type", formik?.values?.investment_amount_type)
            } else {
                setCurrency(investmentSizeList?.currencies[0]?.name)
                formik.setFieldValue("investment_amount_type", investmentSizeList?.currencies[0]?.name)
            }
            if (formik?.values?.investment_size) {
                formik.setFieldValue("investment_size", formik?.values?.investment_size)
                formik.setFieldValue("min", formik?.values?.min)
                formik.setFieldValue("max", formik?.values?.max)
                setSelectedRange(formik?.values?.investment_size)
            } else {
                setSelectedRange(null)
            }
        } else if (currentStep == 10) {
            setIsWhatsAppEnabled(formik?.values?.enabled)
        }else if (currentStep == 11) {
            setIsPublicEnabled(formik?.values?.linkedin_public_profile)
        }
    }, [currentStep])

    useEffect(() => {
        if (currency != null) {
            let modifiedOptions = allAmountRanges.filter(option => { return option.currency == currency })
            setAmountRanges(modifiedOptions)
        }
    }, [currency])

    const handleCurrencyChange = (event) => {
        setCurrency(event.target.value);
        formik.setFieldValue("investment_amount_type", event.target.value)
    };

    const handleRangeSelect = (range) => {
        setSelectedRange(range.title);
        formik.setFieldValue("investment_size", range.title)
        formik.setFieldValue("min", range.min)
        formik.setFieldValue("max", range?.max)
    };

    const getFormattedRange = (range) => {
        const symbol = currency === 'USD' ? '$' : '₹'; // USD or INR symbol

        // Format the range string based on currency
        if (currency === 'INR') {
            // Convert "k" (thousand) to "L" (lakh) for INR and prepend symbol for INR
            const formattedRange = range.replace(/k/g, 'L');
            return `${symbol}${formattedRange} ${currency}`;
        }

        // Default for USD with "k"
        return `${symbol}${range} ${currency}`;
    };
    const [isWhatsAppEnabled, setIsWhatsAppEnabled] = useState(false);
    const [isPublicEnabled, setIsPublicEnabled] = useState(false);

    const handleToggle = () => {
        setIsWhatsAppEnabled(!isWhatsAppEnabled);
        formik.setFieldValue("enabled", !isWhatsAppEnabled)
    };

    const handleTogglePublic = () => {
        setIsPublicEnabled(!isPublicEnabled);
        formik.setFieldValue("linkedin_public_profile", !isPublicEnabled)
    };
    const [isVerify, setIsVerify] = useState(false);

    const handleVerify = () => {
        setIsVerify(!isVerify);
        formik?.handleSubmit()
    };

    const switchGap = () => {
        switch (currentStep) {
            case 1:
                return 0
            case 2:
                return 4
            case 3:
                return 30.5
            default:
                return 0
        }
    }
    const handleUpdateCityName = (city) => {
        formik.setFieldValue("city_id", city?.value)
        // console.log(city,"city")
        setCurrentCity({ label: city?.label, value: city?.value })
        setSearch(city?.label)
    }
    const handleUpdateCountryName = (country) => {
        formik.setFieldValue("country_id", country?._id)
        // console.log(country, "country")
        setCurrentCountry(country)
    }
    const handleUpdateStateName = (state) => {
        // formik.setFieldValue("state_id", state?._id)
        // console.log(state, "state")
        // setCurrentState({ label: country?.name, value: country?._id, id: country?.id })
    }
    const handleSearchCityChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearch(value);
        // refetchCities();
    };
    const handleSearchCountryChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchCountry(value);
        // refetchCities();
    };
    const handleSearchStateChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchState(value);
        // refetchCities();
    };
    const handleSelectionCityChange = (event, value) => {
        // console.log(value, "value")

        formik.setFieldValue("city_id", value ? value?._id : '');
        setCurrentCity(value)
        event.preventDefault();
    };
    const handleSelectionCountryChange = (event, value) => {
        // console.log(value, "value")
        formik.setFieldValue("country_id", value ? value?._id : '');
        setCurrentCountry(value)
        formik.setFieldValue("state_id", null);
        setCurrentState(null)
        setCurrentCity(null);
        formik.setFieldValue("city_id", null)
        setSearchState("")
        setSearch("")
        refetchData()
        event.preventDefault();
    };
    const handleSelectionStateChange = (event, value) => {
        console.log(value, "value")
        formik.setFieldValue("state_id", value ? value?._id : null);
        setCurrentState(value)
        setCurrentCity(null);
        formik.setFieldValue("city_id", null)
        setSearch("")
        refetchData('city')
        event.preventDefault();
    };
    const handleSearchUpdateCity = () => {
        refetchCities();
    }
    const handleSearchUpdateCountry = () => {
        refetchCountries();
    }
    const handleSearchUpdateState = () => {
        refetchStates();
    }
    const refetchData = (type) => {
        if (type === "city") {
            refetchCities().then(response => {
                setTimeout(() => {
                    setCurrentCity(response.data?.find(item => item?._id == formik?.values?.city_id)?.label || '')
                    setSearch("");
                    // setSearch(response.data?.find(item => item?.value == formik?.values?.city_id)?.label || '')
                }, 0)
            })
        }
        else {
            formik.values?.country_id != undefined && refetchStates().then(res => {
                setCurrentState(res?.data?.filter(item => item?._id == formik.values.state_id)[0]?.label || '');
                setSearchState("");
                // setSearchState(res?.data?.find(item => item?.value == formik.values.state_id)?.label || '');
            })

            formik.values?.state_id != undefined && refetchCities().then(response => {
                setTimeout(() => {
                    setCurrentCity(response?.data?.find(item => item?._id == formik?.values?.city_id)?.label || '')
                    setSearch("");
                    // setSearch(response.data?.find(item => item?.value == formik?.values?.city_id)?.label || '')
                }, 200)
            })

        }
        // refetchPopularCities()
    }

    React.useEffect(() => {
        refetchCountries().then(respo => {
            if (formik.values?.country_id != undefined) {
                refetchStates().then(res => {
                    setCurrentState(res?.data?.filter(item => item?._id == formik.values?.state_id)[0])
                }).then(() => {
                    if (formik.values.state_id != undefined) {
                        refetchCities().then(response => {
                            setCurrentCity(response?.data?.find(item => item?._id == formik?.values?.city_id)[0])
                        })
                    }
                })
            }
        })
    }, [])

    const handleInputChange = (event, index) => {
        const { value } = event.target;
        if (value.length === 1 && index < inputRefs.length - 1) {
            inputRefs[index + 1].current.focus();
        }
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value;
        setOtpValues(newOtpValues);
        setError(null);
    };

    const handleKeyDown = (event, index) => {
        // Get the reference to the current input element
        // const currentRef = inputRefs[index];

        if (event.key === 'Backspace' && !event.target.value && index > 0) {
            inputRefs[index - 1].current.focus();
        }
        if (event.key === 'Enter') {
            handleVerifyOTP();
        }

        // Check if the arrow keys are pressed
        if (event.key === 'ArrowRight') {
            // Move focus to the next input if it's not the last one
            if (index < inputRefs.length - 1) {
                inputRefs[index + 1]?.current?.focus();
            }
        } else if (event.key === 'ArrowLeft') {
            // Move focus to the previous input if it's not the first one
            if (index > 0) {
                inputRefs[index - 1]?.current?.focus();
            }
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();

        // Get the pasted data and filter out non-numeric characters
        const pastedData = event.clipboardData
            .getData('text')
            .replace(/\D/g, '') // Remove all non-numeric characters
            .slice(0, 4); // Ensure only the first 4 characters are processed

        const newOtpValues = [...otpValues];

        pastedData.split('').forEach((char, index) => {
            if (inputRefs[index]) {
                inputRefs[index].current.value = char;
                newOtpValues[index] = char;
            }
        });

        setOtpValues(newOtpValues);
    };

    const handleResendingOTP = () => {
        resend({ mobile_number: formik?.values.whatsapp_number }).then(() => {
            setCountDown(30);
        });
    };

    const handleVerifyOTP = () => {
        const OTP = otpValues.join('');
        if (OTP?.trim()?.length > 0) {
            verifyWhatsAppOTPAsync({ enabled: formik?.values?.enabled, otp: OTP });
        }
        else {
            setError("Please enter OTP");
        }
    };

    useEffect(() => {
        if (otpValues?.join("")?.trim()?.length > 0) {
            if (verifyWhatsAppOTPResponse?.data?.statusCode === 200) {
                setTimeout(() => {
                    setError(null)
                    refetchProfileDetails()
                    // setCurrentStep(currentStep + 1);
                    // setSearchParams({ page: currentStep + 1 });
                    navigate('/whatsApp-success');
                }, 100);
            } else if (verifyWhatsAppOTPResponse?.data?.statusCode !== 200) {
                verifyWhatsAppOTPResponse?.data?.message && setError(verifyWhatsAppOTPResponse?.data?.message || null)

            }
        }
    }, [verifyWhatsAppOTPResponse?.data?.statusCode]);


    return (
        <AuthWrapper1>

            {/* <Modal open={isLoading == true || isLoadingCountriesPC}>
                <Wrapper style={{
                    display: 'grid', height: '100vh',
                    width: "100%",
                    placeItems: 'center'
                }}>
                    <CircularProgress sx={{ color: theme?.palette.customColors.b300 }} />
                </Wrapper>
            </Modal> */}
            <Grid xs={12} container display={"flex"} padding={{ xs: "1rem 1rem 1rem 1rem", sm: "2rem" }} sx={{
                maxWidth: '1440px !important',
                marginInline: 'auto',
                height: '100%'
            }}>
                <Grid item xs={12} sm={12} md={6.6} display={"grid"} sx={{ padding: { xs: "1rem 1rem 1rem 1rem", sm: "1rem 2rem", md: "2rem 2rem 1rem 2rem", lg: "2rem 4rem 2rem 5rem" }, height: '100%' }} gap={"1rem"}>
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit} onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey && currentStep != 3) {
                                formik.handleSubmit();
                            }
                        }} style={{ maxHeight: "calc(100vh - 150px)", overflow: 'hidden' }}>
                            <Grid xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"column"} sx={{ maxHeight: "80vh", height: '100%', overflow: "hidden" }}>
                                <CustomHeaderIcon customIcon={currentStep === 2 ? personalInformationImage : currentStep === 3 ? selectCountryImage : currentStep === 6 ? investmentSize : currentStep === 7 ? startupDetailsIcon : currentStep === 8 ? startupInformationIcon : currentStep === 9 ? startupInvestment : currentStep === 10 ? whatsappNotification : currentStep === 11 ? linkedinAddIcon : currentStep === 4 || 5 ? selectCityImage : personalInformationImage} />
                                <Typography variant='title' sx={{ fontSize: { xs: "1.5rem", md: "1.75rem" } }} marginTop={1} textAlign={{ xs: "center", md: "start" }} >{currentStep === 11 ? config?.dynamicText?.linkedInHeading : currentStep === 10 ? config?.dynamicText?.whatsappSettingHeading : currentStep === 9 ? config?.dynamicText?.investmentCountHeading : currentStep === 8 ? config?.dynamicText?.investmentCountHeading : currentStep === 7 ? config?.dynamicText?.stagesHeading : currentStep === 6 ? config?.dynamicText?.sizeHeading : currentStep === 5 ? config?.dynamicText?.sectorHeading : currentStep === 3 ? config?.dynamicText?.countryHeading : currentStep === 4 ? config?.dynamicText?.stateAndCityHeading : currentStep === 1 ? config?.dynamicText?.nameTitle : currentStep === 2 ? config?.dynamicText?.profilePicHeading : ''}</Typography>
                                {currentStep === 1 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginTop={1}>{config?.dynamicText?.nameSubTitle}</Typography>}
                                {currentStep === 2 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1}>{config?.dynamicText?.profilePicSubHeading}</Typography>}

                                {currentStep === 3 && <>
                                    <Typography marginBottom={2} color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginTop={1}>{config?.dynamicText?.countrySubHeading}</Typography>
                                </>}
                                {currentStep === 4 && <>
                                    <Typography marginBottom={2} color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginTop={1}>{config?.dynamicText?.stateAndCitySubHeading}</Typography>
                                </>}
                                {currentStep === 5 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1}>{config?.dynamicText?.sectorSubHeading}</Typography>}
                                {currentStep === 6 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1}>{config?.dynamicText?.sizeSubHeading}</Typography>}
                                {currentStep === 7 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1}>{config?.dynamicText?.stagesSubHeading}</Typography>}
                                {currentStep === 8 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1}>{config?.dynamicText?.investmentCountSubheading}</Typography>}
                                {currentStep === 9 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1}>{config?.dynamicText?.investmentSubHeading}</Typography>}
                                {currentStep === 10 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1}>{config?.dynamicText?.whatsappSettingSubHeading}</Typography>}
                                {currentStep === 11 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{ xs: "center", md: "start" }} marginBlock={1}>{config?.dynamicText?.linkedInSubHeading}</Typography>}
                                {/* {currentStep === 4 && <Typography color={"gray"} variant='bodyTextRegular' textAlign={{xs: "center", md: "start"}} marginTop={1}>Link your linkedin profile</Typography>} */}

                                {currentStep === 5 &&
                                    <>
                                        {/* <Grid xs={12}> */}
                                        <label style={{ marginTop: '1.5rem', fontSize: '0.875rem', color: '#0f1319', marginBottom: '8px' }} >
                                            {/* WHAT ARE THE SECTORS OF YOUR STARTUP?{" "} */}
                                            {config?.validationFields?.sectors?.label}
                                            {formik.errors.sector_id && formik.touched.sector_id && (
                                                <Typography
                                                    component={isDesktop ? "span" : isTablet ? "span" : "p"}
                                                    style={{
                                                        color: "rgb(239, 83, 80)",
                                                        fontWeight: "400",
                                                        fontSize: "0.75rem",
                                                        lineHeight: "1.66",
                                                        letterSpacing: "0.03333em",
                                                        textAlign: "left",
                                                        marginTop: "3px",
                                                        marginRight: "14px",
                                                        marginBottom: "0",
                                                        marginLeft: "14px",
                                                    }}
                                                >
                                                    {formik.errors.sector_id}
                                                </Typography>
                                            )}
                                        </label>
                                        {/* </Grid> */}
                                    </>
                                }
                                {currentStep === 6 &&
                                    <>
                                        <Grid container alignItems="center" justifyContent="space-between">
                                            <Grid item>
                                                <CustomLabel>{config?.validationFields?.size?.label}</CustomLabel>
                                            </Grid>

                                            <Grid item display="flex" alignItems="center">
                                                <Select
                                                    labelId="currency-label"
                                                    id="currency-select"
                                                    value={currency}
                                                    onChange={handleCurrencyChange}
                                                    sx={{
                                                        border: 'none',
                                                        color: '#344054',
                                                        fontSize: '0.875rem',
                                                        borderRadius: 0,
                                                        padding: 0,
                                                        margin: 0,
                                                        minWidth: '80px',
                                                        boxShadow: 'none',
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none',
                                                        },
                                                        '&:focus': {
                                                            outline: 'none',
                                                        }
                                                    }}
                                                >
                                                    {
                                                        currencyOptions?.map(item => {
                                                            return (
                                                                <MenuItem value={item.name}>{item.name}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                                {currentStep === 7 &&
                                    <>
                                        <CustomLabel sx={{ marginTop: "1.5rem" }}>{config?.validationFields?.stages?.label} {formik.errors.stage_id && (
                                            <CustomValidationText value={formik.errors.stage_id} />
                                        )}</CustomLabel>
                                    </>
                                }

                                {currentStep === 8 &&
                                    <>
                                        <CustomLabel sx={{ marginTop: "1.5rem" }}>{config?.validationFields?.investment_count?.label} </CustomLabel>
                                    </>
                                }
                                {
                                    currentStep === 11 &&
                                    <>
                                        <Grid
                                            container
                                            xs={12}
                                            gap={1}
                                            paddingBottom={2}

                                        >

                                            <Grid item xs={12} position="relative" pt={1}>
                                                <CustomInputField
                                                    label={config?.validationFields?.linkedIn_profile_url?.label}
                                                    name={'profile_url'}
                                                    value={formik?.values?.profile_url}
                                                    placeholder={config?.validationFields?.linkedIn_profile_url?.placeholder}
                                                // onChange={(e) =>
                                                //     formik.setFieldValue(
                                                //         `startups.${index}.startup_name`,
                                                //         e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1)
                                                //     )
                                                // }
                                                />
                                            </Grid>
                                            <Grid
                                                role="button"
                                                alignItems="center"
                                                display={'flex'}
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                sx={{
                                                    background: `${theme?.palette?.customColors?.y50}`,
                                                    padding: "1px 16px",
                                                    height: 55,
                                                    borderRadius: "8px",
                                                    display: "grid",
                                                    gridTemplateColumns: "auto 1fr auto",
                                                    border: `1px solid ${theme?.palette?.customColors?.y300}`,
                                                    boxShadow: "0px 1px 2px 0px #1018280D",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <img src={eye_icon} alt='whats' width={20} height={20} style={{ marginRight: '0.5rem' }} />

                                                <Typography
                                                    variant="subtitle2"
                                                    fontWeight={400}
                                                    color={theme.palette.customColors.g300}
                                                    fontSize="1rem"
                                                >
                                                    {config?.dynamicText?.enableDiscover}
                                                </Typography>
                                                <Switch checked={isPublicEnabled} onChange={handleTogglePublic} />
                                            </Grid>
                                            <Typography variant='caption' fontWeight={400} pt={1} fontSize={'14px'}>
                                                {config?.dynamicText?.dicoverMessage}
                                            </Typography>
                                        </Grid>
                                    </>
                                }
                                <PerfectScrollbar containerRef={containerRef} style={{
                                    minHeight: "35vh", height: '100%',
                                    paddingRight: isDesktop ? "0.5rem" : 0,
                                    //  maxHeight: 'calc(100vh - 205px)',
                                    overflowX: 'hidden', marginTop: (currentStep === 5 || currentStep === 6 ? 0 : "1.5rem"), paddingBottom: currentStep === 5 || currentStep === 6 ? 0 : '1.5rem'
                                }} className="scrollbar-container" id='onboarding-scroller'>

                                    {currentStep === 1 &&
                                        <>
                                            <Grid container xs={12} gap={0} marginTop={0} display={"flex"} alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"space-between"}>
                                                <Grid item container xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"row"}>
                                                    <CustomLabel>{config?.validationFields?.title?.label}  {formik.errors.title && formik.touched.title && (
                                                        <CustomValidationText value={formik.errors.title} />
                                                    )}</CustomLabel>
                                                    <Grid
                                                        display={"flex"}
                                                        flexWrap={"wrap"}
                                                        justifyContent={"space-between"}
                                                        item
                                                        container
                                                        xs={12}
                                                        gap={{ xs: 0.5, sm: 1, md: 2 }}
                                                        mb={2}
                                                    >
                                                        {title?.map((referral, index) => {
                                                            return (
                                                                <Grid
                                                                    role={"button"}
                                                                    item
                                                                    // xs={12}
                                                                    xs={3.8}
                                                                    sm={1.65}
                                                                    md={1.6}
                                                                    lg={1.65}

                                                                    onClick={() => {
                                                                        formik.setFieldValue(
                                                                            "title",
                                                                            referral?.value
                                                                        );
                                                                    }}
                                                                    sx={{
                                                                        background:
                                                                            formik.values.title ==
                                                                                referral?.value
                                                                                ? theme?.palette?.customColors?.y50
                                                                                : theme?.palette?.customColors?.whiteBG,
                                                                        padding: "12px 16px",
                                                                        height: 55,
                                                                        borderRadius: "8px",
                                                                        display: "grid",
                                                                        placeItems: "center",
                                                                        border: `1px solid ${formik.values.title ==
                                                                            referral?.value
                                                                            ? theme?.palette?.customColors?.y300
                                                                            : theme?.palette?.customColors?.g75
                                                                            }`,
                                                                        boxShadow: "0px 1px 2px 0px #1018280D",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        color={theme.palette.customColors.g200}
                                                                        fontSize="0.875rem"
                                                                    >
                                                                        {referral?.value}
                                                                    </Typography>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                    {formik.values.title === "Other" && <Grid item xs={12} display={"flex"} gap={0} marginTop={2}>
                                                        <CustomInputField value={formik.values.title} name="title" label={"ENTER REFERRER NAME"} placeholder="Enter referrer name" onChange={(e) => formik.setFieldValue("title", e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} />
                                                    </Grid>}
                                                </Grid>
                                                <Grid height={"min-content"} item xs={12} sm={12} md={5.5} lg={5.65}>
                                                    <CustomInputField name={`first_name`} label={config?.validationFields?.first_name?.label} fullWidth />
                                                </Grid>
                                                <Grid height={"min-content"} item xs={12} sm={12} md={5.5} lg={5.65}>
                                                    <CustomInputField name={`last_name`} label={config?.validationFields?.last_name?.label} fullWidth />
                                                </Grid>
                                                <Grid container gap={{ xs: 0.5, sm: 1, md: 2 }} display={'flex'} justifyContent={"space-between"}>
                                                    <Grid height={"min-content"} item xs={3.8} sm={3.8} md={3.65} lg={3.65}>
                                                        <CustomInputField name={`mm`} label={config?.validationFields?.dob?.label} placeholder={config?.validationFields?.dob?.monthPlaceholder} fullWidth />
                                                    </Grid>
                                                    <Grid height={"min-content"} item xs={3.8} sm={3.8} md={3.65} lg={3.65}>
                                                        <CustomInputField name={`dd`} label={'dd'} placeholder={config?.validationFields?.dob?.datePlaceholder} fullWidth
                                                            labelStyle={{ opacity: 0 }} />
                                                    </Grid>
                                                    <Grid height={"min-content"} item xs={3.8} sm={3.8} md={3.65} lg={3.65}>
                                                        <CustomInputField name={`yyyy`} label={'yyyy'} placeholder={config?.validationFields?.dob?.yearPlaceholder} fullWidth labelStyle={{ opacity: 0 }} />
                                                    </Grid>
                                                </Grid>
                                                {/* {isLoadingCountriesPC ? <CircularProgress sx={{ color: "#3538CD" }} /> :  */}
                                                {/* {formik.values.phone_code && <Grid height={"min-content"} item xs={12}>
                                                    <CustomPhone name={`mobile_number`} selectedCode={formik.values.phone_code ? formik.values.phone_code : ""} handleChange={(event) => formik.setFieldValue("phone_code", event.target.value)} countryCodes={countriesWithPhoneCodes ? countriesWithPhoneCodes : []} label='MOBILE' placeholder="Enter your mobile number" onChange={(e) => formik.setFieldValue(`mobile_number`, e.target.value)} />
                                                </Grid>} */}
                                                {/* } */}
                                            </Grid>

                                        </>}

                                    {currentStep === 2 &&
                                        <>
                                            <Grid container xs={12} gap={0} sx={{ overflowX: "hidden" }}>
                                                <Grid item xs={12} sm={12}>
                                                    <CustomLogoButton label={config?.dynamicText?.uploadPhoto} handleUpdateFile={(file) => { formik.setFieldValue("custom_image_url", file); formik.setFieldValue("avatar_selected", null) }} name={"custom_image_url"} value={formik.values.custom_image_url} placeholder={placeHolderImage} Pheight={100} Pwidth={100} />
                                                    {/* <CustomUploadButton label="STARTUP LOGO" handleUpdateFile={(evt) => { formik.setFieldValue("startup_logo", evt.target.files[0]) }} selectedFile={formik.values.startup_logo} /> */}
                                                </Grid>
                                            </Grid>
                                            <Grid position={'relative'} pt={2}
                                                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                            >
                                                <Divider sx={{ flex: 1 }} />
                                                <Typography sx={{ width: "2rem", textAlign: "center" }}>{config?.dynamicText?.or}</Typography>
                                                <Divider sx={{ flex: 1 }} />
                                            </Grid>
                                            <Grid mt={1}>
                                                <Typography variant='h2'>{config?.dynamicText?.profilePicTitle}</Typography>
                                                <Typography variant='subtitle1'>{config?.dynamicText?.profilePicSubTitle}</Typography>
                                            </Grid>
                                            <Grid display={'flex'} gap={3} pt={2}>
                                                {
                                                    avatarList?.map(item => {
                                                        return (
                                                            <div
                                                                style={{ border: formik.values.avatar_selected == item._id ? '4px solid rgba(255, 201, 0, 1)' : "4px", backgroundColor: formik.values.avatar_selected == item._id ? 'rgba(255, 250, 230, 1)' : 'transparent', borderRadius: '40px' }}
                                                                onClick={() => { formik.setFieldValue("avatar_selected", item._id); formik.setFieldValue("custom_image_url", null) }}>
                                                                <img style={{ height: "70px", width: "70px", objectFit: "fill" }} src={item.image_url} onError={(ev) => { ev.target.src = reserch }} alt='avtar'></img>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {/* <img src={reserch} alt='avtar'></img>
                                                <img src={reserch} alt='avtar'></img>
                                                <img src={reserch} alt='avtar'></img> */}
                                            </Grid>
                                        </>}
                                    {currentStep === 3 &&
                                        <>
                                            {/* {console.log(countries,"countries")} */}
                                            {!isLoadingCountries && !isRefetchingCountries ? <CustomAutoComplete
                                                options={!isLoadingCountries && !isRefetchingCountries ? countries : []}
                                                label={config?.dynamicText?.search}
                                                name={"country_id"}
                                                noOptionsText={!isLoadingCountries && !isRefetchingCountries ? "No Options" : "Searching..."}
                                                placeholder={config?.dynamicText?.searchPlaceholder}
                                                icon={gpsIcon}
                                                handleSearchChange={handleSearchCountryChange}
                                                handleSelectionChange={handleSelectionCountryChange}
                                                search={searchCountry}
                                                value={currentCountry}
                                                defaultValue={countries?.find(item => item?._id == formik?.values?.country_id)}
                                                loading={isRefetchingCountries}
                                                handleSearchUpdate={handleSearchUpdateCountry}
                                            /> : <>
                                                <Box className="skeleton" sx={{ height: 17.6, width: '100%', marginBottom: 1 }}></Box>
                                                <Box className="skeleton" sx={{ height: 50.6, width: '100%', marginBottom: 2 }}></Box>
                                                {/* <Box className="skeleton" sx={{ height: 17.6, width: '100%', marginBottom: 1 }}></Box> */}
                                            </>}
                                            <CustomLabel>POPULAR COUNTRIES</CustomLabel>
                                            <Grid item container xs={12} gap={2} display={"flex"} justifyContent={"space-between"} paddingBottom={{ xs: "0rem" }}>
                                                {popular_countries?.map((country, index) => <Grid key={index} onClick={() => handleUpdateCountryName(country)} item xs={5.6} sm={5.8} md={5.75} lg={5.8} flexGrow={1}
                                                >
                                                    <button onClick={(e) => e.preventDefault()} style={{
                                                        minHeight: "3.1rem",
                                                        maxHeight: "5rem",
                                                        height: "100%",
                                                        padding: "0.5rem 0.2rem 6rem 0.5rem",
                                                        background: formik.values.country_id === country?._id ? "#FFFAE6" : "#FFF",
                                                        fontSize: "0.875rem",
                                                        cursor: 'pointer',
                                                        border: `1px solid ${formik.values.country_id === country?._id ? "#FFC900" : "#A1A3A7"}`,
                                                        borderRadius: "8px",
                                                        overflow: "hidden",
                                                        display: "flex",
                                                        width: '100%',
                                                        justifyContent: "space-between"
                                                    }}>

                                                        <span style={{ textAlign: 'left' }}>{country?.label}</span>
                                                        <img loading='lazy' src={country?.image_url} style={{ ...country?.size, marginLeft: 'auto' }} />
                                                    </button>
                                                </Grid>
                                                )}
                                            </Grid>
                                        </>}

                                    {currentStep === 4 &&
                                        <>
                                            {!isLoadingStates && !isRefetchingStates ? <CustomAutoComplete
                                                options={!isLoadingStates && !isRefetchingStates && states?.length > 0 ? states : []}
                                                label={`${config?.dynamicText?.searchState} ${String(currentCountry?.label || countries?.find(item => item?._id === formik.values.country_id)?.label)?.toUpperCase()}`}
                                                placeholder="Type a state name"
                                                name="state_id"
                                                noOptionsText={!isLoadingStates && !isRefetchingStates ? "No Options" : "Searching..."}
                                                icon={gpsIcon}
                                                handleSearchChange={handleSearchStateChange}
                                                handleSelectionChange={handleSelectionStateChange}
                                                search={searchState}
                                                value={currentState?.label}
                                                defaultValue={states?.find(item => item?._id === formik?.values?.state_id)?.label}
                                                loading={false}
                                                handleSearchUpdate={handleSearchUpdateState}
                                            /> : <>
                                                <Box className="skeleton" sx={{ height: 17.6, width: '100%', marginBottom: 1 }}></Box>
                                                <Box className="skeleton" sx={{ height: 50.6, width: '100%', marginBottom: 2 }}></Box>
                                            </>}
                                            {!isLoadingCities && !isRefetchingCities && (currentState !== null ? states?.find(item => item?._id == formik.values?.state_id)?.value : currentState?.value) && <CustomAutoComplete
                                                options={!isLoadingCities && !isRefetchingCities && cities?.length > 0 ? cities : []}
                                                noOptionsText={!isLoadingCities && !isRefetchingCities ? "No Options" : "Searching..."}
                                                label={`SEARCH CITY IN ${String(currentState?.label || states?.find(item => item?._id === formik.values.state_id)?.label)?.toUpperCase()}`}
                                                placeholder="Type a city name"
                                                name="city_id"
                                                icon={gpsIcon}
                                                handleSearchChange={handleSearchCityChange}
                                                handleSelectionChange={handleSelectionCityChange}
                                                search={search}
                                                value={currentCity?.label}
                                                defaultValue={cities?.find(item => item?._id === formik?.values?.city_id)?.label}
                                                handleSearchUpdate={handleSearchUpdateCity}
                                            />}

                                        </>}

                                    {currentStep === 6 &&
                                        <>
                                            <Grid
                                                display={"flex"}
                                                justifyContent={"space-between"}
                                                flexWrap={"wrap"}
                                                item
                                                container
                                                xs={12}
                                                gap={"1rem"}
                                            >
                                                {amountRanges?.map((item, index) => {
                                                    return (
                                                        <Grid
                                                            // role={"button"}
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                        >
                                                            <button
                                                                type="button"
                                                                onClick={() => handleRangeSelect(item)}
                                                                style={{
                                                                    background: selectedRange === item.title ? theme?.palette?.customColors?.y50
                                                                        : theme?.palette?.customColors
                                                                            ?.whiteBG,
                                                                    padding: "12px 16px",
                                                                    height: 55,
                                                                    borderRadius: "8px",
                                                                    display: "grid",
                                                                    placeItems: "center",
                                                                    border: `1px solid ${selectedRange === item.title
                                                                        ? theme?.palette?.customColors?.y300
                                                                        : theme?.palette?.customColors?.g75
                                                                        }`,

                                                                    boxShadow: "0px 1px 2px 0px #1018280D",
                                                                    cursor: "pointer",
                                                                    fontSize: "0.875rem",
                                                                    color: theme.palette.customColors.g200,
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                {/* {security?.name} */}
                                                                {item.title}
                                                            </button>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </>
                                    }

                                    {currentStep === 5 && (
                                        <>
                                            <Grid item container xs={12} display={"flex"} paddingBottom={{ xs: 0, sm: 1, md: 3, lg: 5 }}>
                                                <FieldArray
                                                    name="sector_id"
                                                    render={arrayHelpers => (
                                                        <>
                                                            <ul style={{ display: 'flex', gap: "1rem", flexWrap: 'wrap', padding: 0, marginTop: 0 }}>
                                                                {startup_sectors?.map((sector) => {
                                                                    const isSelected = formik.values.sector_id?.includes(sector?._id);
                                                                    return (
                                                                        <li
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                gap: 0,
                                                                                background: isSelected ? "#FFFAE6" : "#FFF",
                                                                                fontSize: "0.875rem",
                                                                                border: `1px solid ${isSelected ? "#FFC900" : "#A1A3A7"}`,
                                                                                borderRadius: "8px",
                                                                                paddingRight: "1rem",
                                                                                height: "2.5rem",
                                                                                cursor: "pointer",
                                                                                textTransform: 'none',
                                                                                color: 'inherit',
                                                                                // opacity: formik.values.sector_id?.length >= 1 && !isSelected ? 0.5 : 1, // Dims unselected options when max is reached
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault()
                                                                                if (isSelected) {
                                                                                    arrayHelpers.remove(formik.values.sector_id.indexOf(sector?._id));
                                                                                } else {
                                                                                    arrayHelpers.push(sector?._id);
                                                                                }
                                                                                console.log("arrayHelpers", arrayHelpers)
                                                                            }}
                                                                            key={sector?._id}
                                                                        >
                                                                            {/* <img
                                                                            src={sector?.image_url}
                                                                            rel='preload'
                                                                            style={{
                                                                                minHeight: "1rem",
                                                                                maxHeight: "1rem",
                                                                                height: "100%",
                                                                            }}
                                                                            loading='lazy'
                                                                        /> */}
                                                                            <i style={{ ...sector?.style }}>

                                                                            </i>

                                                                            <span> {sector?.name} </span>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </>
                                                    )}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {currentStep === 7 &&
                                        <>
                                            <Grid item container xs={12} gap={2} display={"flex"} paddingBottom={4}>
                                                {startup_stages?.map((stage, index) => {
                                                    const isSelected = formik.values.stage_id?.includes(stage?._id);
                                                    return (
                                                        <Grid key={index}
                                                            onClick={() => {
                                                                if (isSelected) {
                                                                    selectedStages.splice(formik.values.stage_id.indexOf(stage?._id), 1);
                                                                } else {
                                                                    selectedStages.push(stage?._id);
                                                                }
                                                                formik.setFieldValue("stage_id", selectedStages)
                                                            }
                                                            } item xs={12} sm={5.8} md={5.6} lg={5.75} flex={1} >
                                                            <button key={index} onClick={(e) => e.preventDefault()} style={{
                                                                minHeight: "2.5rem",
                                                                height: "100%",
                                                                maxHeight: '4rem',
                                                                width: "100%",
                                                                padding: "1rem 0rem 6rem 0.5rem",
                                                                background: isSelected ? "#FFFAE6" : "#FFF",
                                                                fontSize: "0.875rem",
                                                                cursor: 'pointer',
                                                                border: `1px solid ${isSelected ? "#FFC900" : "#A1A3A7"}`,
                                                                borderRadius: "8px",
                                                                overflow: "hidden",
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                position: 'relative'
                                                            }}>
                                                                <span style={{ position: 'absolute', fontWeight: 600, top: "5px" }}>
                                                                    {stage?.name}
                                                                </span>
                                                                <img src={stage?.image_url} rel='preload' style={{ ...stage?.size, marginLeft: 'auto' }} />
                                                            </button>
                                                        </Grid>
                                                    )
                                                }
                                                )}
                                            </Grid>
                                        </>}
                                    {currentStep === 8 &&
                                        <>
                                            <Grid container xs={12} gap={0} marginTop={0} display={"flex"} alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"space-between"}>
                                                <Grid item container xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"row"}>
                                                    <Grid
                                                        display={"flex"}
                                                        flexWrap={"wrap"}
                                                        justifyContent={"space-between"}
                                                        item
                                                        container
                                                        xs={12}
                                                        gap={{ xs: 0.5, sm: 1, md: 2 }}
                                                        mb={2}
                                                    >
                                                        {number_investment?.map((num, index) => {
                                                            const isSecondLast = index === number_investment.length - 2;
                                                            const isLast = index === number_investment.length - 1;
                                                            return (
                                                                <Grid
                                                                    role={"button"}
                                                                    item
                                                                    xs={isSecondLast || isLast ? 12 : 2}
                                                                    sm={isSecondLast || isLast ? 12 : 2}
                                                                    md={isSecondLast || isLast ? 12 : 2}
                                                                    lg={isSecondLast || isLast ? 12 : 2.1}
                                                                    onClick={() => {
                                                                        formik.setFieldValue(
                                                                            "investment_count",
                                                                            num?.value
                                                                        );
                                                                    }}
                                                                    sx={{
                                                                        background:
                                                                            formik.values.investment_count ==
                                                                                num?.value
                                                                                ? theme?.palette?.customColors?.y50
                                                                                : theme?.palette?.customColors?.whiteBG,
                                                                        padding: "12px 16px",
                                                                        height: 55,
                                                                        borderRadius: "8px",
                                                                        display: "grid",
                                                                        placeItems: "center",
                                                                        border: `1px solid ${formik.values.investment_count ==
                                                                            num?.value
                                                                            ? theme?.palette?.customColors?.y300
                                                                            : theme?.palette?.customColors?.g75
                                                                            }`,
                                                                        boxShadow: "0px 1px 2px 0px #1018280D",
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        color={theme.palette.customColors.g200}
                                                                        fontSize="0.875rem"
                                                                    >
                                                                        {num?.label}
                                                                    </Typography>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>

                                                </Grid>

                                            </Grid>
                                        </>
                                    }
                                    {currentStep === 9 &&
                                        <>
                                            <Grid
                                                container
                                                xs={12}
                                                display="flex"
                                                gap={0}
                                                justifyContent="space-between"
                                                flexDirection="column"
                                                paddingBottom={2}
                                            >
                                                <FieldArray
                                                    name="startups"
                                                    render={(arrayHelpers) => (
                                                        <>
                                                            {formik.values?.startups?.length > 0 &&
                                                                formik.values?.startups?.map((current, index) => (
                                                                    <Box key={index}>
                                                                        {current?.edit ? (
                                                                            <>

                                                                                <Grid item xs={12} position="relative" pt={1}>
                                                                                    <CustomInputField
                                                                                        label="STARTUP NAME"
                                                                                        name={`startups.${index}.startup_name`}
                                                                                        value={current?.name}
                                                                                        placeholder="Startup Name"
                                                                                        onChange={(e) =>
                                                                                            formik.setFieldValue(
                                                                                                `startups.${index}.startup_name`,
                                                                                                e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1)
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Grid>
                                                                                <Box>
                                                                                    <Grid item xs={12} display="flex">
                                                                                        <CustomUrlField
                                                                                            label="STARTUP WEBSITE URL"
                                                                                            name={`startups.${index}.startup_url`}
                                                                                            value={current?.startup_url}
                                                                                            onChange={(e) =>
                                                                                                formik.setFieldValue(
                                                                                                    `startups.${index}.startup_url`,
                                                                                                    String(e.target.value)?.startsWith("https://")
                                                                                                        ? String(e.target.value)?.replace("https://", "")
                                                                                                        : e.target.value
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                </Box>
                                                                            </>
                                                                        ) : (
                                                                            <Box
                                                                                display="flex"
                                                                                alignItems="center"
                                                                                justifyContent="space-between"
                                                                                border="1px solid #A1A3A7"
                                                                                padding="7px 18px"
                                                                                borderRadius="4px"
                                                                            >
                                                                                <Box display="flex" alignItems="center" color={theme?.palette?.customColors?.g100} gap={1}>
                                                                                    <span>{current?.startup_name}</span>
                                                                                </Box>
                                                                                <Box display="flex" gap={1}>
                                                                                </Box>
                                                                            </Box>
                                                                        )}
                                                                        {formik.values?.startups?.length - 1 === index && (
                                                                            <>
                                                                                <Box
                                                                                    sx={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        gap: 1,
                                                                                        justifyContent: "space-between",
                                                                                    }}
                                                                                >
                                                                                    <Box
                                                                                        sx={{ cursor: "pointer" }}
                                                                                        onClick={() => {
                                                                                            if (current?.startup_name !== "" && current?.startup_url !== "") {
                                                                                                formik.setFieldValue(`startups.${index}`, { ...current, edit: false }).then(() =>
                                                                                                    arrayHelpers.insert(index + 1, {
                                                                                                        startup_name: "",
                                                                                                        startup_url: "",
                                                                                                        edit: true,
                                                                                                    })
                                                                                                );
                                                                                            } else {
                                                                                                formik.handleSubmit();

                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <IconButton>
                                                                                            <Tooltip title="Add next startup">
                                                                                                <img src={AddIcon} rel="preload" width={32} height={32} />
                                                                                            </Tooltip>
                                                                                        </IconButton>
                                                                                        <Typography marginLeft="1rem" variant="caption" color="#000">
                                                                                            Add another startup
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Box>
                                                                                <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: "space-between" }}>
                                                                                    <Typography
                                                                                        color={theme?.palette.customColors.b300}
                                                                                        fontSize="1rem"
                                                                                    >
                                                                                    </Typography>
                                                                                </Box>
                                                                            </>
                                                                        )}
                                                                        {formik.values?.startups?.length > 1 && <Divider sx={{ marginTop: 2, marginBottom: 2 }} />}
                                                                    </Box>
                                                                ))}
                                                        </>
                                                    )}
                                                />
                                            </Grid>
                                        </>
                                    }
                                    {currentStep === 10 &&
                                        <>
                                            <Grid
                                                role="button"
                                                alignItems="center"
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                sx={{
                                                    background: `${theme?.palette?.customColors?.y50}`,
                                                    padding: "0px 16px",
                                                    height: 55,
                                                    borderRadius: "8px",
                                                    display: "grid",
                                                    gridTemplateColumns: "auto 1fr auto",
                                                    border: `1px solid ${theme?.palette?.customColors?.y300}`,
                                                    boxShadow: "0px 1px 2px 0px #1018280D",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <img src={whatsapp_icon} alt='whats' width={20} height={20} style={{ marginRight: '0.5rem' }}></img>
                                                <Typography
                                                    variant="subtitle2"
                                                    color={theme.palette.customColors.g200}
                                                    fontSize="0.875rem"
                                                >
                                                    Enable WhatsApp notification
                                                </Typography>
                                                <Switch checked={isWhatsAppEnabled} onChange={handleToggle} />
                                            </Grid>
                                            {isWhatsAppEnabled && (
                                                <>
                                                    <Grid height="min-content" item xs={12} sm={12} md={12} lg={12} pt={4}>
                                                        <CustomInputField name="whatsapp_number" label="MOBILE NUMBER" fullWidth />
                                                    </Grid>
                                                    <Typography
                                                        color={theme.palette.customColors.b300}
                                                        fontSize="1rem"
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            textDecoration: 'underline',
                                                            textUnderlineOffset: '5px',
                                                            marginTop: '0.5rem',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={handleVerify}
                                                    >
                                                        <span>Verify mobile number</span>
                                                    </Typography>
                                                    {isVerify && (
                                                        <>
                                                            <Grid container xs={12} display={'grid'} sx={{ placeItems: "flex-start", placeContent: "stretch", gap: "1rem" }}>
                                                                <Typography color={"gray"} variant="subtitle2" mt={"2rem"} mb={"0.5rem"}>ENTER OTP</Typography>
                                                                <Grid container xs={12} gap={2}>
                                                                    {inputRefs?.map((ref, index) => (
                                                                        <Grid item flex={1} key={index}>
                                                                            <CustomInputFieldOTP
                                                                                inputProps={inputProps}
                                                                                required
                                                                                sx={{ marginBottom: 0 }}
                                                                                inputRef={ref}
                                                                                onChange={(event) => handleInputChange(event, index)}
                                                                                onKeyDown={(event) => handleKeyDown(event, index)}
                                                                                onPaste={index === 0 ? handlePaste : undefined}
                                                                            />
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                                {error !== null && <CustomValidationText style={{ marginTop: "-0.5rem" }} value={error} />}
                                                                {isLoading ? (
                                                                    <Typography color={theme.palette.customColors.b300} sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: '1rem' }} fontSize="1rem">
                                                                        <CircularProgress sx={{ color: theme.palette.customColors.b300 }} size="1rem" /> <span>Resending OTP</span>
                                                                    </Typography>
                                                                ) : (
                                                                    <Typography color={theme.palette.customColors.b300} fontSize="1rem" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'underline', textUnderlineOffset: '5px', marginTop: '1rem' }}>
                                                                        <span onClick={countDown === 0 ? handleResendingOTP : undefined} style={{ color: countDown > 0 ? theme.palette.customColors.b75 : theme.palette.customColors.b300, cursor: 'pointer' }}>{config?.dynamicText?.resend}</span>
                                                                        <span>&nbsp;{countDown > 0 && `(${countDown} Sec)`}</span>
                                                                    </Typography>
                                                                )}
                                                            </Grid>
                                                        </>
                                                    )}

                                                </>
                                            )}


                                        </>
                                    }

                                </PerfectScrollbar>
                                {/* {currentStep === 4 && <>
                                        <Grid container xs={12} gap={0} marginTop={0}>
                                            
                                            <Grid xs={12} item container style={{ display: 'flex', alignItems: 'center' }}>
                                                <CustomUrlField label={"LinkedIn URL"} name="linkedin_url" value={formik.values?.linkedin_url} onChange={(e) => formik.setFieldValue("linkedin_url", String(e.target.value)?.startsWith("https://") ? String(e.target.value)?.replace("https://", "") : e.target.value)} placeholder='www.linkedin.com' />
                                            </Grid>
                                        </Grid>
                                    </>} */}

                            </Grid>
                        </Form>
                    </FormikProvider>
                    <Grid item container xs={12} display={"flex"} alignItems={"flex-end"} justifyContent={"center"} gap={3} >
                        {currentStep !== 1 && currentStep !== 11 && <Grid xs={5} md={5.5} lg={5.65} item>
                            <BLUEBORDER sx={{ textTransform: "none", padding: 2, borderWidth: 1, borderRadius: "8px", height: '56.5px' }} type="no usetr" onClick={() => {
                                if (currentStep === 1) {
                                    Cookies.remove("jwt");
                                    navigate("/login");
                                }
                                else {
                                    setCurrentStep(currentStep - 1);
                                };
                                setSearchParams({ page: currentStep - 1 });
                                window.scrollTo(0, 0);
                                //  refetchData();
                            }} fullWidth>Back</BLUEBORDER>
                        </Grid>}
                        <Grid xs={5} md={5.5} lg={5.65} item>
                            <CustomButton sx={{ padding: 2, borderRadius: "8px", height: '56.5px' }} onClick={() => {
                                if (currentStep == 10) {

                                    if (formik.values.enabled) {
                                        if (isVerify) {
                                            handleVerifyOTP()

                                        } else {
                                            formik.handleSubmit()
                                            if (formik?.values?.whatsapp_number?.trim()?.length > 0) {
                                                setIsVerify(true)
                                            }
                                        }
                                    } else {
                                        setCurrentStep(currentStep + 1);
                                        setSearchParams({ page: currentStep + 1 });
                                    }
                                } else {
                                    formik.handleSubmit()
                                }
                                // if (currentStep === 10) {
                                //     navigate('/whatsApp-success');  
                                //   }
                                // setCurrentStep(currentStep + 1);
                            }} fullWidth> {isUpdating ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.4rem'} /> : "Next"} </CustomButton>
                        </Grid>
                    </Grid>
                </Grid>

                <CustomBackgroundComponent />
            </Grid>

        </AuthWrapper1 >
    )
}

export default ProfileInformation
