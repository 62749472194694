import React, { useState } from 'react';
import Button from '@mui/material/Button';
import UploadIcon from '../../assets/upload_icon_svg.svg';
import { Box, Divider, Grid, InputLabel, Typography } from '@mui/material';
import CustomLabel from '../CustomLabel';
import { useField } from 'formik';
import { ReactComponent as UploadDocumentsIcon } from "../../assets/rounds_icons/upload_documents_icon.svg";
import { Close } from '@mui/icons-material';
import CustomValidationText from '../CustomValidationText';

const CustomUploadButton = ({ label, handleUpdateFile, selectedFile, name, accept = "*/*", typeSpecifyText = "PNG, JPG only (Max: 5 MB)", customUploadIcon }) => {
  const [field, mata] = useField(name);
  const [isRemoveButtonPressed, setIsRemoveButtonPressed] = useState(true)
  const [isUploadButtonPressed, setIsUploadButtonPressed] = useState(true)

  const handleFileChange = (file) => {
    // No specific file handling for dimensions, just pass the file
    handleUpdateFile(file);
    // setError('');
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileChange(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileChange(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Box
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    // sx={{
    //   border: '1px dashed rgba(102, 112, 133, 0.5)',
    //   borderRadius: '8px',
    //   padding: '1rem',
    //   textAlign: 'center',
    //   position: 'relative',
    //   backgroundColor: '#FFF',
    //   color: '#667085',
    // }}
    >
      <CustomLabel>{label}</CustomLabel>
      <input
        accept={accept}
        style={{ display: 'none' }}
        id="upload-button-file"
        type="file"
        onChange={handleFileInputChange}
      />
      <label htmlFor={((typeof selectedFile != 'string' || typeof selectedFile == 'string') && selectedFile != null && selectedFile != 'null') ? "" : "upload-button-file"}>
        <Button
          variant="outlined"
          component="span"
          fullWidth
          disableRipple
          startIcon={<img src={customUploadIcon ? customUploadIcon : UploadIcon} style={{ marginRight: customUploadIcon ? "-0.5rem" : "0.5rem", marginLeft: customUploadIcon ? 2 : "-0.25rem" }} width={40} height={40} />}
          sx={{
            borderRadius: '8px',
            display: 'grid',
            placeItems: 'center',
            padding: '1rem',
            color: '#667085',
            backgroundColor: '#FFF',
            textTransform: 'none',
            '& > div > span.highlight': {
              color: '#6941C6',
            },
            border: '1px solid rgb(102, 112, 133,0.5)',
            ":hover": {
              backgroundColor: "rgba(255,255,255,1)",
              border: '1px solid rgb(102, 112, 133,0.5)',
            }
          }}
        >
          {((typeof selectedFile != 'string' || typeof selectedFile == 'string') && selectedFile != null && selectedFile != 'null') ? (
            <Typography  fontSize={"0.8rem"} mt={1} textAlign="center" >
              {/* <strong>Uploaded File:</strong> */}
               {typeof selectedFile != "string" ? ((selectedFile?.name)?.length > 20 ? (selectedFile?.name)?.slice(0,20) + "..." : selectedFile?.name) : ((selectedFile)?.length > 20 ? (selectedFile)?.slice(0,20) + "..." : selectedFile)}

              <Grid item container xs={12} display={"flex"} alignItems="center" flexWrap={"nowrap"} justifyContent={"center"} gap={1} mt={1} >
                <Grid xs={5.5} item sx={{ display: 'flex', justifyContent: 'center', maxWidth: 'max-content' }}>
                  <InputLabel onMouseDown={() => setIsUploadButtonPressed(false)} onMouseUp={() => setIsUploadButtonPressed(true)} htmlFor="upload-button-file" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', opacity: isUploadButtonPressed ? 1 : 0.2, cursor: 'pointer', gap: 5, width: '100%' }}>
                    <UploadDocumentsIcon
                      style={{
                        width: 16,
                        height: 16,
                        stroke: 'rgb(105, 65, 198)'
                      }}
                    />
                    <span className='highlight' style={{ color: "#6941C6", textAlign: 'center' }}>Replace</span>
                  </InputLabel>
                </Grid>
                <Divider orientation="vertical" flexItem />
                {<Grid xs={5.5} item>
                  <InputLabel onClick={(event) => { handleUpdateFile(null); handleDragOver(event) }} onMouseDown={() => setIsRemoveButtonPressed(false)} onMouseUp={() => setIsRemoveButtonPressed(true)} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: isRemoveButtonPressed ? 1 : 0.2, cursor: 'pointer', gap: 0.5, maxWidth: 'max-content', width: '100%' }}>
                    <Close sx={{ width: 16, height: 16, color: 'rgb(239, 83, 80)', margin: 0 }} />
                    <span className='' style={{ color: "rgb(239, 83, 80)", textAlign: 'center' }}>Remove</span>
                  </InputLabel>
                </Grid>}
              </Grid>
            </Typography>
          ) : <>
            <div>
              <span className='highlight'>Click to upload </span>
              <span>or drag and drop</span>
            </div>
            <small>{typeSpecifyText}</small></>}

        </Button>
      </label>
      {
        mata && mata.error && (
          <CustomValidationText value={mata.error} />
        )
      }
      {/* {console.log(typeof selectedFile,selectedFile ,"type")} */}
    </Box>
  );
};

export default CustomUploadButton;
