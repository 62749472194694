import React from 'react'
import whatsappsuccess from '../assets/whatsappsuccess.png'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@emotion/react'

const WhatsAppSuccessLogoImage = ({alignCenter, style = {}}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  return (
    <img style={{ height: "4.5rem", maxWidth: "4.5rem", aspectRatio: 1, marginInline:  isSmallScreen || isMediumScreen || alignCenter ? "auto" : 0, ...style}} src={whatsappsuccess} />
  )
}

export default WhatsAppSuccessLogoImage