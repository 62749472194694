import { Box, Container } from '@mui/material';
import React from 'react';
// import { Container } from '@material-ui/core';

const MainContent = ({ children }) => {
  return (
    <Box style={{ margin: 0, background: '#3538CD', minHeight: 'calc(100vh - 20px)', width: "100%" }}>
      {children}
    </Box>
  );
};

export default MainContent;
